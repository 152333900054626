/* --------------------------
見出し
-------------------------- */



.c-h1 {
  margin-top: 190px;
  color: $color-black;
  text-align: center;
  font-weight: 400;
  letter-spacing: 5px;
  padding: 25px;
  font-size: nth($font-size, 1);
  line-height: nth($line-height, 1);
  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
    line-height: nth($line-height-sp, 1);
    padding: 15px;
    margin-top: 110px;
  }

  small {
    display: block;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 3px;
  }
}

.c-h2 {
  text-align: center;
  margin: 0 auto 30px;
  font-weight: $bold;
  font-size: 27px;
  line-height: 1.3;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  font-family: 'Tenor Sans', 'Noto Serif JP', serif;

  &.bule {
    color: $color-main;
  }
  @include view-at(pc) {
  }

  span {
    display: block;
    font-size: 10px;
    margin-top: 15px;
    font-family: 'Noto Sans JP', sans-serif;

    @include view-at(pc) {
      font-size: 14px;
      margin-top: 20px;
    }
  }
}

.c-h3 {
  text-align: center;
  margin: 30px auto 30px;
  font-weight: $bold;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.15em;
  text-indent: 0.2em;
  position: relative;
  font-family: 'Tenor Sans', 'Noto Serif JP', serif;

  @include view-at(pc) {
    margin: 40px auto 30px;
    font-size: 20px;
  }

  small {
    font-size: 70%;
    letter-spacing: 0.1em;
  }

  img {
    width: 25px;
  }
}

.c-h4 {
  font-size: nth($font-size, 3);
  line-height: nth($line-height, 3);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 20px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: nth($line-height-sp, 3);
  }
}

.c-h5 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.c-h6 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}
