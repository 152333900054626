@charset "UTF-8";


// スライドカスタマイズ
.slick-slide {
  img {
    margin: 0 auto;
  }
}
.slick-dots {
  bottom: -30px;
}

.slick-dots li {
  margin: 0 2px;
  width: 20px;
  height: 10px;
}
.slick-dots li button:before {
  color: #9E9E9F;
  font-size: 20px;
}
.slick-dots li.slick-active button:before,
.slick-dots li:hover button:before {
  color: #294C74;
  opacity: 1;
}


.top {
  &-infomation {
    margin-top: 80px;
    @include view-at(pc) {
      margin-top: 120px;
    }
    &.news {
      @include view-at(pc) {
        margin-top: 170px;
      }
    }

    table {
      max-width: 600px;
      margin: 0 auto;

      tr {
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        letter-spacing: 0.1em;
        line-height: 1.8em;
        max-width: 600px;

        @include view-at(sp) {
          display: block;
        }

        th {
          padding-right: 20px;
          letter-spacing: 0.1em;

          @include view-at(sp) {
            display: block;
          }
        }
      }
    }

    ul {
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 50px;
      @include view-at(sp) {
        margin-bottom: 30px;
      }
      li {
        margin-bottom: 30px;
        a {
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          letter-spacing: 0.1em;
          line-height: 1.8em;
          @include view-at(sp) {
            display: block;
          }
        }
      }
    }
    &-date {
      padding-right: 20px;
      letter-spacing: 0.1em;
      text-align: left;

      @include view-at(sp) {
        display: block;
      }
    }
    &-text {
      text-align: left;
    }
  }

  &-campaign {
    @include view-at(pc) {
      margin-top: 170px;
    }

    ul.u-row {
      margin: -20px;
      @include view-at(sp) {
        margin: -10px;
      }
    }

    &-item {
      padding: 20px;
      @include view-at(sp) {
        padding: 10px;
      }

      &-img {
        margin-bottom: 30px;
        @include view-at(sp) {
          margin-bottom: 10px;
        }
      }
      tr {
        display: block;
        font-size: 14px;
        line-height: 1.8em;

        th {
          display: block;
          letter-spacing: 0.1em;
        }
      }
    }
  }

  &-Ranking {
    @include view-at(pc) {
      margin-top: 200px;
    }

    &-wrap {
      @include view-at(sp) {
        overflow-x: scroll;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -20px;

      @include view-at(sp) {
        flex-wrap: unset;
        width: 820px;
        margin: 0 -10px;
        margin-bottom: 10px;
      }
    }

    &-item {
      width: calc(100%/5);
      padding: 20px;

      @include view-at(sp) {
        width: auto;
        padding: 10px;
      }

      &-text {
        background: #EFEFEF;
        color: $color-main;
        padding: 30px 10px;
        text-align: center;
      }
      &-num {
        font-size: 32px;
        margin-bottom: 15px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      }
      &-ttl {
        margin-bottom: 5px;
        letter-spacing: 0.2em;
        font-size: 16px;
      }
      &-price {
        font-size: 13px;
        margin-bottom: 0;
        letter-spacing: 0.1em;
      }
      &-img {
        position: relative;

        .hart {
          position: absolute;
          bottom: 10px;
          right: 10px;
          width: 15px;
          height: 15px;
          background: url(../../img/usr/common/icon-hart.png) no-repeat;
          background-size: 100%;
        }
      }
    }
  }

  &-otoku {
    background: #e0ecf5;

    @include view-at(pc) {
      margin-top: 200px;
    }

    &-inner {
      max-width: 810px;
      margin: 0 auto;
    }

    h2 {
      letter-spacing: 0.3em;
      font-size: 26px;
      line-height: 1.5em;
      @include view-at(sp) {
        line-height: 1.4em;
      }

      small {
        letter-spacing: 0.3em;
        font-size: 60%;
        display: block;
        @include view-at(sp) {
          line-height: 1.5em;
          letter-spacing: 0.1em;
          margin-bottom: 10px;
        }
      }
    }

    ul {
      @include view-at(sp) {
        margin: -10px;
      }
      li {
        @include view-at(sp) {
          padding: 10px;
        }
      }
    }

    &-btn {
      background: $color-gray-snow;
      border: none;
    }
  }

  &-btn {
    &01 {
      background: white;
      position: relative;
      font-size: 15px;
      border: 1px solid $color-gray-light;
      letter-spacing: 0.2em;
      width: 100%;
      line-height: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      font-weight: 400;
      @include view-at(sp) {
        font-size: 13px;
      }
      img {
        width: 50%;
        @include view-at(sp) {
          width: 40%;
        }
      }

      &-inner {
        width: 50%;
        @include view-at(sp) {
          width: 60%;
        }
      }
      span {
        position: relative;
        letter-spacing: 0.1em;
        @include view-at(sp) {
          display: block;
        }

        &::after {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          right: -40px;
          content: '▶';
          display: inline-block;
          color: #666464;
          font-size: 8px;

          @include view-at(sp) {
            right: 10px;
          }
        }
      }
    }
    &02 {
      background: white;
      position: relative;
      font-size: 15px;
      padding: 30px 20px;
      border: 1px solid $color-gray-light;
      letter-spacing: 0.2em;
      width: 100%;
      line-height: 1;
      display: block;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      font-weight: 400;
      @include view-at(sp) {
        font-size: 13px;
        padding: 20px;
      }
      span {
        letter-spacing: 0.1em;
        position: relative;
        @include view-at(sp) {
          display: block;
        }

        &::after {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          right: -40px;
          content: '▶';
          display: inline-block;
          color: #666464;
          font-size: 8px;
          @include view-at(sp) {
            right: -10px;
          }
        }
      }
    }
    &03 {
      background: white;
      position: relative;
      border: 1px solid $color-gray;
      letter-spacing: 0.2em;
      width: 100%;
      line-height: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      font-weight: 400;
      text-decoration: none;
      @include view-at(sp) {
        font-size: 13px;
      }
      img {
        width: 40%;
        @include view-at(sp) {
          width: 100%;
        }
      }

      &-inner {
        width: 60%;
        @include view-at(sp) {
          width: 100%;
        }

        p {
          font-size: 28px;
          letter-spacing: 0.3em;
          margin-bottom: 30px;
          @include view-at(sp) {
            font-size: 19px;
            margin: 15px 0;
          }
        }
      }
      span {
        position: relative;
        letter-spacing: 0.1em;
        max-width: none;
        @include view-at(sp) {
          width: 100%;
        }
      }
    }
  }

  &-products {
    @include view-at(pc) {
      margin-top: 200px;
    }

    &-wrap {
      margin: 80px 0 120px;

      @include view-at(sp) {
        margin: 50px 0 70px;
      }

    }
    ul {
      li {
        margin-bottom: 20px;
        @include view-at(sp) {
          margin-bottom: 0px;
        }
        a {
          display: block;
        }
      }
    }
    &-item {
      > h3 {
        margin: 0;
        padding: 0;
        margin-top: 20px;
        @include view-at(sp) {
          line-height: 1.5em;
        }
        small {
          @include view-at(sp) {
            font-size: 17px;
          }
        }

        &.series-text {
          small {
            font-size: 10px;
            letter-spacing: 0;
          }
        }
      }
      &-thum {
        img {
          max-width: 100px;
          @include view-at(sp) {
            max-width: 70px;
          }
        }
      }
      &-img {
        @include view-at(sp) {
          padding: 10px;
        }
        &.series-img {
          padding: 0;
        }
      }
      &-ttl {
        margin-top: 20px;
        font-size: 14px;
        letter-spacing: 0.2em;
        text-align: center;
        line-height: 1.3;
        &.border {
          border: 1px solid $color-gray-light;
          padding: 15px;
          margin-top: 0;
        }
        &-big {
          font-size: 24px;
          line-height: 1.1em;
          @include view-at(sp) {
            line-height: 1.2em !important;
          }
          small {
            font-size: 14px;
          }
        }
      }
      
    }

    &-btn {
      max-width: 800px;
      margin: 0 auto;
      li {
        padding: 20px;
        @include view-at(sp) {
          padding: 10px;
          text-align: center;
        }

        a {
          width: 100%;

          @include view-at(sp) {
            max-width: 350px;
            margin: 0 auto;
          }
        }
      }
    }
  }

  &-Special {
    h2 {
      letter-spacing: 0em;
    }
    @include view-at(pc) {
      margin-top: 200px;
      h2 {
        letter-spacing: 0.6em;
      }
    }
    ul {
      margin: -20px;
      li {
        padding: 20px;
      }
    }
    &-item {
      &-img {
        margin-bottom: 40px;
        @include view-at(sp) {
          margin-bottom: 20px;
        }
      }
      &-text {
        text-align: center;

        p {
          font-size: 14px;
          letter-spacing: 0.1em;
          margin-bottom: 10px;
          line-height: 1.8em;
        }
        a.btn {
          font-size: 14px;
          position: relative;
          padding-right: 30px;

          &::after {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 5px;
            font-size: 12px;
            content: '〉';
            display: inline-block;
            color: #1A556F;
            font-weight: bold;
          }
        }
      }
    }
  }

  &-news {
    @include view-at(pc) {
      margin-top: 200px;
    }

    &-wrap {
      margin-bottom: 20px;

      table {
        tr {
          display: flex;
          line-height: 1.8em;
          margin-bottom: 30px;
          font-size: 14px;
          max-width: 720px;

          @include view-at(sp) {
            display: block;
          }

          th {
            padding-right: 30px;

            @include view-at(sp) {
              display: block;
              padding-right: 0;
            }
          }
        }
      }
    }

    a.btn {
      font-size: 14px;
      position: relative;
      padding-right: 30px;

      &::after {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 5px;
        font-size: 12px;
        content: '〉';
        display: inline-block;
        color: #1A556F;
        font-weight: bold;
      }
    }
  }

  &-insta {
    @include view-at(pc) {
      margin-top: 200px;
    }

    &-item {
      position: relative;

      &::after {
        content: "";
        width: 25px;
        height: 25px;
        background: url(../../img/usr/common/icon-insta.png) no-repeat;
        background-size: cover;
        position: absolute;
        bottom: 5px;
        left: 5px;
      }
    }
  }

  &-menu {
    @include view-at(pc) {
      margin-top: 200px;
    }

    &-wrap {
      @include view-at(sp) {
        margin: 0 -20px;
      }

      ul {
        display: grid;
        display: -ms-grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1.1fr;
        -ms-grid-columns: 1fr 1fr 1fr 1.1fr;
        -ms-grid-rows: 1fr 1fr 1fr 1fr;
        height: 470px;

        @include view-at(sp) {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          height: 400px;
        }

        li {
          grid-column: 1 / 2;
          transition: all 0.5s ease 0s;

          &:hover {
            opacity: 0.7;
          }
          
          a {
            display: block;
            height: 100%;
            text-decoration: none;

            > div {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              color: white;

              .ttl {
                font-size: 19px;
                letter-spacing: 0.15em;
                line-height: 1.5em;
                text-align: center;
                font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                // filter: drop-shadow(1px 1px 2px black);
                @include view-at(sp) {
                  font-size: 17px;
                  letter-spacing: 0.1em;
                }
                &-big {
                  font-size: 30px;
                  letter-spacing: 0.2em;
                  line-height: 1.5em;
                  text-align: center;
                  font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                  // filter: drop-shadow(1px 1px 2px black);
                  @include view-at(sp) {
                    font-size: 22px;
                  }
                }
              }
            }
          }

          &:nth-child(1) {
            grid-row: 1 / 5;
            grid-column: 1 / 4;
            -ms-grid-column: 1;
            -ms-grid-column-span: 3;
            -ms-grid-row: 1;
            -ms-grid-row-span: 4;
            background: url(../../img/usr/main/menu1.jpg) no-repeat;
            background-size: cover;

            @include view-at(sp) {
              grid-row: 1 / 3;
              grid-column: 1 / 3;
            }

            a {
              > div {
                justify-content: flex-start;
                margin-left: 10%;
                @include view-at(sp) {
                  justify-content: center;
                  margin-left: 0;
                }
              }
            }

            .img {
              border-right: 1px solid;
              padding-right: 30px;
              // filter: drop-shadow(1px 1px 2px black);
            }
            .text {
              padding-left: 30px;
              text-align: center;
              
              &-ttl {
                font-size: 27px;
                margin-bottom: 20px;
                letter-spacing: 0.1em;

                &-sub {
                  font-size: 14px;
                }
              }
            }
          }
          &:nth-child(2) ,
          &:nth-child(3) ,
          &:nth-child(4) ,
          &:nth-child(5) {
            grid-column: 4/5;
            -ms-grid-column: 4;
          }
          &:nth-child(2) {
            -ms-grid-row: 1;
            background: url(../../img/usr/main/menu2.jpg) no-repeat center;
            background-size: cover;
            @include view-at(sp) {
              grid-row: 3/4;
              grid-column: 1/2;
            }
          }
          &:nth-child(3) {
            -ms-grid-row: 2;
            background: url(../../img/usr/main/menu3.jpg) no-repeat center;
            background-size: cover;
            @include view-at(sp) {
              grid-row: 3/4;
              grid-column: 2/3;
            }
          }
          &:nth-child(4) {
            -ms-grid-row: 3;
            background: url(../../img/usr/main/menu4.jpg) no-repeat center;
            background-size: cover;
            @include view-at(sp) {
              grid-row: 4/5;
              grid-column: 1/2;
            }
          }
          &:nth-child(5) {
            -ms-grid-row: 4;
            background: url(../../img/usr/main/menu5.jpg) no-repeat center;
            background-size: cover;
            @include view-at(sp) {
              grid-row: 4/5;
              grid-column: 2/3;
            }
          }
        }
      }
    }
  }

  &-bnr {
    @include view-at(pc) {
      margin-top: 150px;
      margin-bottom: 150px;
    }
  }
}


// アニメーション
.scrollfade {
  opacity: 0;
  transition: all 2s ease 0s;

  &.show {
    opacity: 1;
  }
}

.fade-list {
  li {
    opacity: 0;
    transform: translateY(30px);
    transition: all 2s ease 0s;
    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }
}