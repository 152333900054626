/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: inline-block;
  width: auto;
  padding: 15px 40px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: $color-black;
  background-color: $color-gray-light;
  position: relative;
  font-weight: $bold;
  font-size: nth($font-size-sp, 6);
  letter-spacing: 0.15em;
  font-family: 'Tenor Sans', 'Noto Serif JP', serif;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &--line {
    padding: 0;
    border-bottom: 1px solid $color-black;
    background: none;
    padding-bottom: 5px;
  }

  &--arrow {
    position: relative;
    font-size: 15px;
    padding: 20px 20px;
    background: none;
    border: 1px solid $color-text;
    letter-spacing: 0.2em;
    width: 350px;
    line-height: 1;
    max-width: 100%;

    @include view-at(sp) {
      max-width: 350px;
      width: 100%;
      margin: 0 auto;
      padding: 15px 20px;
    }

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      content: '▶';
      display: inline-block;
      color: $color-text;
      font-size: 8px;
      @include view-at(sp) {
        right: 10px;
      }
    }

    &.bk{
      background: $color-text;
      color: white;

      &::after {
        color: white;
      }
    }

    &.a-left {
      &::after {
        left:20px;
        right: auto;
        content: '◀';
        @include view-at(sp) {
          left: 10px;
        }
      }
    }
    &.center {
      margin: 0 auto;
    }
  }

  &--arrow2 {
    position: relative;
    font-size: 15px;
    letter-spacing: 0.2em;
    background: none;
    padding: 0;

    @include view-at(sp) {
      font-size: 14px;
    }

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -20px;
      content: '>';
      display: inline-block;
      color: $color-text;
      font-size: 12px;
    }
  }

  &--arrow3 {
    position: relative;
    font-size: 15px;
    padding: 10px 20px;
    background: none;
    border: 1px solid $color-main;
    letter-spacing: 0.2em;
    width: 350px;
    max-width: 100%;
    line-height: 1;
    color: $color-main;
    display: block;
    margin-bottom: 10px;

    @include view-at(sp) {
      width: 100%;
    }

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      content: '▶';
      display: inline-block;
      color: $color-main;
      font-size: 8px;
      @include view-at(sp) {
        right: 10px;
      }
    }

    input {
      background: none;
      color: $color-main;
      border: none;
      font-size: 13px;
    }
  }

  &--arrow4 {
    position: relative;
    font-size: 14px;
    padding: 20px 40px;
    background: $color-main;
    border-radius: 40px;
    letter-spacing: 0.2em;
    line-height: 1;
    color: white;

    @include view-at(sp) {
      margin: 0 auto;
      padding: 15px 40px;
    }

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      content: '▼';
      display: inline-block;
      color: white;
      font-size: 8px;
    }
  }
   

  &--primary {
    font-size: 12px;
    background: none;
    padding: 0;
    width: 100%;
    text-align: right;

    span {
      display: inline-block;
      margin-left: 10px;
      width: 50px;
      height: 1px;
      background-color: $color-gray;
      position: relative;
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation: arrow01 5s ease-in-out infinite forwards;
      animation: arrow01 5s ease-in-out infinite forwards;
      margin-bottom: 3px;

      &::after {
        content: "";
        display: block;
        width: 8px;
        height: 10px;
        border-right: 1px solid $color-gray;
        transform: rotate(-45deg) scale(1, 0);
        transform-origin: right bottom;
        position: absolute;
        bottom: 1px;
        right: 1px;
        animation: arrow02 5s ease-in-out infinite forwards;
      }

    }

    @keyframes arrow01 {
      0% { transform: scale(0, 1); }
      30% { transform: scale(0, 1); }
      40% { transform: scale(1, 1); }
      85% { transform: scale(1, 1); opacity: 1;}
      90% { transform: scale(1, 1); opacity: 0; }
      100% { transform: scale(1, 1); opacity: 0; }
    }

    @keyframes arrow02 {
      0% { transform: rotate(-45deg) scale(1, 0); }
      45% { transform: rotate(-45deg) scale(1, 0); }
      55% { transform: rotate(-45deg) scale(1, 1); }
      85% { transform: rotate(-45deg) scale(1, 1); opacity: 1;}
      90% { transform: rotate(-45deg) scale(1, 1); opacity: 0; }
      100% { transform: rotate(-45deg) scale(1, 1); opacity: 0; }
    }
  }

  &--sorting {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: $color-main;
    border: 1px solid $color-main;
    position: relative;
    font-weight: $bold;
    font-size: nth($font-size-sp, 6);
    letter-spacing: 0.15em;

    img {
      position: absolute;
      left: 18px;
    }
  }

  &--border {
    display: inline-block;
    width: auto;
    padding: 10px 40px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    color: $color-black;
    position: relative;
    font-weight: $bold;
    font-size: nth($font-size-sp, 6);
    letter-spacing: 0.15em;
    color: black;
    border: 1px solid black;

    @include view-at(pc) {
      font-size: 14px;
    }
  }

  &--campain {
    font-size: 12px;
    background: none;
    border: 1px solid $color-black;
    position: relative;
    padding: 20px 0;
    width: 250px;
    margin: 0 auto;
    letter-spacing: 0.15em;

    @include view-at(pc) {
      font-size: 16px;
      width: 350px;
    }

    &::after {
      content: "";
      border-top: 1px solid;
      position: absolute;
      top: 50%;
      right: -25px;
      width: 50px;
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: $color-gray-light;
    border-color: $color-gray-light;
    color: $color-white;
  }

  &--large {
    padding: 20px;
    font-size: nth($font-size, 3);
    min-width: 200px;
  }

  &--small {
    padding: 10px;
    font-size: nth($list: $font-size, $n: 5)
  }

  &--category {
    background: $color-main;
    color: white;
    font-family: $text-go;
    padding: 25px;
    width: 100%;
    font-size: 16px;

    &::after {
      content: "";
      position: absolute;
      top: 42%;
      right: 18px;
      width: 10px;
      height: 10px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      display: inline-block;
  }
  }
}
