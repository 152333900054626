/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  background: $color-bg;
  margin-top: 180px;
  @include view-at(sp) {
    margin-top: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 20px 10px;
    display: flex;

    li {
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 10px;
      position: relative;
      padding-right: 25px;
      color: $color-text-gray;

      @include view-at(pc) {
        font-size: 12px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 3px;
        right: 10px;
        width: 7px;
        height: 7px;
        border-top: 1px solid $color-text-gray;
        border-right: 1px solid  $color-text-gray;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        display: inline-block;

        @include view-at(sp) {
          width: 5px;
          height: 5px;
        }
      }

      &:last-child {
        &::after {
          content: none;
        }
      }

      a {
        color: $color-gray;
      }

      span {
        letter-spacing: 0.2em;
      }
    }
  }


}
