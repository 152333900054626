.fade-in {
    opacity: 0;
    transition-duration: 1.5s;
    transition-property: opacity, transform;
  }
  
  .fade-in-up {
    transform: translate(0, 50px);
  }
  
  .fade-in-down {
    transform: translate(0, -50px);
  }
  
  .fade-in-left {
    transform: translate(-50px, 0);
  }
  
  .fade-in-right {
    transform: translate(50px, 0);
  }
  
  .scroll-in {
    opacity: 1;
    transform: translate(0, 0);
  }

