@charset "UTF-8";

.Products {
  
  &-ttl {
    margin: 30px 0;

    @include view-at(pc) {
      margin: 60px 0;
    }

    &-h2 {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      font-family: $text-go;

      @include view-at(pc) {
        font-size: 30px;
      }
    }

    &-text {
      font-size: 12px;

      @include view-at(pc) {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  &-text {
    margin: 40px 0;
    @include view-at(pc) {
      margin: 80px 0;
    }
  }

  &-btns {
    margin: 30px 0 20px;

    @include view-at(pc) {
      margin: 60px 0 20px;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: -5px;

      > li {
        width: calc(100%/ 2);
        padding: 5px;
        max-width: 250px;

        .sort {
          overflow: hidden;
          text-align: center;
          height: 100%;
          position: relative;
          border-radius: 2px;
          background: #ffffff;
          text-align: center;

          &::before {
            content: "";
              position: absolute;
              top: 40%;
              left: 18px;
              width: 8px;
              height: 8px;
              border-top: 1px solid $color-black;
              border-right: 1px solid $color-black;
              -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
              display: inline-block;
          }

          select {
            width: 100%;
            cursor: pointer;
            appearance: none;
            height: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
            outline: none;
            background: transparent;
            color: $color-black;
            border: 1px solid $color-black;
            padding: 15px 20px;
            padding-left: 40%;
            font-size: 12px;
            font-family: $text-go;
            letter-spacing: 0.15em;
            font-weight: 400;

            &::-ms-expand {
              display: none;
            }

            option{
              padding: 10px 0;
              letter-spacing: 0.1em;
            }

            @include view-at(se) {
              font-size: 10px;
            }
          }
        }

        a {
          text-decoration: none;
          color: #231815;
          border: 1px solid #E6E6E6;
          text-align: center;
          justify-content: center;
          font-weight: 400;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;

          @include view-at(sp) {
            padding-left: 50px;
          }
          @include view-at(se) {
            font-size: 10px;
            padding-left: 40px;
            padding-right: 10px;
          }
        }
      }
    }

    &-sort {
      text-decoration: none;
      color: #231815;
      border: 1px solid #E6E6E6;
      justify-content: center;
      background: no-repeat;
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      position: relative;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.15em;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;

      &::before {
        content: "";
        position: absolute;
        top: 40%;
        left: 18px;
        width: 8px;
        height: 8px;
        border-top: 1px solid #231815;
        border-right: 1px solid #231815;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        display: inline-block;
        z-index: +1;
      }

      input {
        width: 100%;
        display: block;
        background: none;
        border: none;
        padding: 15px 20px;
      }

      &-list {
        width: 100%;
        margin: 0;
        padding: 5px 0;
        border: 1px solid $color-gray-light;
        display: none;
        position: absolute;
        background: #fff;
        z-index: +1;

        > li {
          text-align: center;
          padding: 5px;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;

          input {
            display: none;
          }
        }
      }
    }

    &-wrap {
      position: relative;
    }

    .is-open .Products-btns-sort-list {
      display: block;
    }
  }

  &-head {
    margin: 25px 0 20px;

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 14px;
      
      @include view-at(pc) {
        font-size: 16px;
      }
    }
    &-pager {
      a {
        padding: 5px 10px;
        background: $color-gray-snow;
        font-size: 16px;
        line-height: 1;
        font-weight: bold;
        display: inline-block;

        &.active,
        &:hover {
          background: $color-main;
          color: white;
          opacity: 1;
        }
      }
    }
  }

  &-item {
    margin: 20px 0;

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -10px;
      @include view-at(sp) {
        margin: -5px;
      }

      li {
        width: 25%;
        padding: 10px;

        @include view-at(sp) {
          width: 50%;
          padding: 5px;
        }
      }

      &-inner {
        padding: 10px;
        background: $color-gray-snow;
        position: relative;
        height: 100%;

        @include view-at(sp) {
          padding: 10px;
        }
      }
    }

    &-img {
      position: relative;

      &-like {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 20px;
      }
      &-point {
        position: absolute;
        top: 5px;
        left: 5px;
        height: 25px;
        
        @include view-at(sp) {
          height: 20px;
        }

        > img {
          width: auto;
          height: 100%;
        }
        &-text {
          display: block;
          margin-bottom: 5px;
          color: $color-main;
          font-size: 12px;
          @include view-at(sp) {
            font-size: 10px;
          }
        }
      }
      &-date {
        position: absolute;
        top: 5px;
        left: 5px;
        color: $color-main;
        letter-spacing: 0.1em;
        font-size: 12px;
        @include view-at(sp) {
          font-size: 10px;
        }
      }
    }

    &-text {
      padding: 15px;
      padding-bottom: 5px;
      text-align: left;

      @include view-at(sp) {
        padding: 10px 0 0;
      }

      &-ttl {
        font-size: 17px;
        padding-bottom: 5px;
        letter-spacing: 0.1em;
        @include view-at(sp) {
          font-size: 14px;
          line-height: 1.2;
        }
      }
      &-price {
        font-size: 14px;
        letter-spacing: 0.1em;
        margin-bottom: 10px;
        @include view-at(sp) {
          font-size: 14px;
          line-height: 1;
        }
      }
      &-tag {
        span {
          margin-bottom: 5px;
          display: inline-block;
          margin-right: 5px;
          height: 20px;
        
          @include view-at(sp) {
            height: 18px;
          }

          > img {
            width: auto;
            height: 100%;
          }
        }
      }
    }

    &-cart {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;

      @include view-at(sp) {
        top: 0px;
        right: 0px;
        width: 30px;
      }
    }

    &-modal {
      padding: 0 !important;

      @include view-at(pc) {
        padding: 30px !important;
      }

      &-wrap{
        height: 100% !important;
        padding: 0 !important;
        margin: 0 !important;

        @include view-at(pc) {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
        }
      }
      
      &-top {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 20px 30px;
        position: relative;

        @include view-at(pc) {
          padding: 0;
          padding-right: 20px;
        }
        @include view-at(tab) {
          padding: 10px 20px 30px;
        }

        &-img{
          padding: 10px;
          
          @include view-at(pc) {
            padding: 10px 0;
          }
          @include view-at(tab) {
            padding: 10px 0;
          }
          @include view-at(sp) {
            width: 40%;
          }
        }

        &-text {
          margin-bottom: 20px;
          @include view-at(sp) {
            width: 60%;
            padding-left: 20px;
            margin-bottom: 0;
          }
        }

        &-left {
          width: 45%;
          @include view-at(tab) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
        }

        &-ttl {
          font-size: 20px;
          letter-spacing: 0.1em;
          margin-bottom: 5px;
          @include view-at(sp) {
            font-size: 16px;
          }
        }
        &-price{
          font-size: 14px;
          letter-spacing: 0.1em;
        }
      }

      &-color {
        border: 1px solid $color-gray-light;
        border-radius: 10px;
        margin-bottom: 30px;
        @include view-at(sp) {
          margin-bottom: 20px;
        }
      }

      &-btn {
        padding: 30px 0;
        @include view-at(pc) {
          padding: 10px 0;
          padding-right: 0;
          padding-top: 0;
        }
        @include view-at(tab) {
          width: 100% !important;
          padding: 10px 0;
        }

        &-wrap {
          margin-bottom: 10px;

          a {
            padding: 20px;
            text-align: center;
            font-size: 20px;
            background: $color-main;
            display: block;
            color: white;
            letter-spacing: 0.1em;
            position: relative;
            height: 65px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
  
            span {
              line-height: 1.3em;

              small {
                font-size: 12px;
                display: block;
                text-align: center;
                letter-spacing: 0.2em;
                line-height: 1.3;
              }
            }
  
            &::after {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 20px;
              content: '▶';
              display: inline-block;
              color: $color-white;
              font-size: 8px;
            }
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 10px;
              width: 35px;
              height: 35px;
              background: url(../../img/usr/products/btn-cart.png) no-repeat center;
              background-size: 100%;
            }
          }
          &:nth-last-child(1) {
            a {
              background: #3081BE;
              span {
                letter-spacing: 0.1em;
              }

              &::before {
                background: url(../../img/usr/products/btn-regular.png) no-repeat center !important;
                background-size: 100%;
              }
            }
          }
        }
        
      }

      &-tel {
        width: 100%;

        @include view-at(pc) {
          margin-top: 10px;
          border-radius: 5px;
        }

        h5 {
          font-size: 14px;
          text-align: center;
          color: $color-main;
          margin-bottom: 10px;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          
          @include view-at(pc) {
            line-height: 1.5em;
          }
        }

        &-wrap {
          text-align: center;

          @include view-at(pc) {
            border: none;
            padding: 0;
          }

          &-ttl {
            font-size: 14px;
            text-align: center;
            margin-bottom: 10px;
          }

          a {
            color: #3081BE;
            font-size: 28px;
            text-align: center;
            margin-bottom: 10px;
            display: block;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            letter-spacing: 0.1em;

            span {
              padding-right: 10px;
            }
          }
          p {
            font-size: 12px;
            letter-spacing: 0.1em;
          }
        }
      }

      &-regular {
        background: #e0ecf5;
        padding: 30px 20px;
        text-align: center;
        position: relative;

        @include view-at(pc) {
          width: 40%;
        }
        @include view-at(tab) {
          width: 100% !important;
        }
        @include view-at(sp) {
          &::before {
            content: "";
            position: absolute;
            top: -30px;
            left: 50%;
            margin-left: -15px;
            border: 15px solid transparent;
            border-bottom: 15px solid #e0edff;
        }
        }

        

        .regular-ttl {
          font-size: 16px;
          text-align: center;
          border-bottom: 1px solid;
          padding-bottom: 10px;
          margin-bottom: 20px;
          letter-spacing: 0.1em;
          color: $color-main;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          @include view-at(pc) {
            font-size: 18px;
          }
        }

        ul {
          display: flex;
          justify-content: center;
          margin: -5px -5px 20px;

          li {
            padding: 5px;
            width: calc(100% /3);
          }
        }

        a {
          text-decoration: underline;
          font-size: 12px;
          letter-spacing: 0.1em;
          color: $color-main;
        }
      }

      &-left {
        @include view-at(pc) {
          width: 60%;
          flex-wrap: wrap;
        }
        @include view-at(tab) {
          width: 100% !important;
        }
      }

      &-right {
        width: 55%;
        padding-left: 20px;
        @include view-at(sp) {
          width: 100%;
          margin-top: 0;
          padding-left: 0;
          margin-top: 10px;
        }
        @include view-at(tab) {
          width: 100% !important;
          margin-top: 10px;
        }
      }
    }
  }

  &-under {
    margin: 25px 0 20px;

    &-inner {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-size: 14px;
      
      @include view-at(pc) {
        font-size: 16px;
      }
    }

    &-pager {
      a {
        padding: 5px 10px;
        background: $color-gray-snow;
        font-size: 16px;
        line-height: 1;
        font-weight: bold;
        display: inline-block;

        &.active,
        &:hover {
          background: $color-main;
          color: white;
          opacity: 1;
        }
      }
    }
  }

  &-link {
    max-width: 850px;
    margin: 60px auto;

    @include view-at(sp) {
      padding: 0 20px;
    }

    ul {
      li {
        text-align: center;
        @include view-at(sp) {
          padding: 15px 0;
        }
        a {
          width: 100%;
          max-width: 350px;
        }
      }
    }
  }

  

  // カテゴリーページ
  &-category {
    margin-bottom: 80px;

    @include view-at(sp) {
      margin-bottom: 40px;
    }

    h5 {
      font-size: 20px;
      color:$color-main;
      text-align: center;
      margin-bottom: 30px;
      font-weight: bolder;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      letter-spacing: 0.1em;
      @include view-at(sp) {
        margin-bottom: 20px;
      }
    }

    &-wrap {
      padding: 30px;
      background: $color-gray-snow;

      @include view-at(sp) {
        padding: 0;
        background: none;
      }

      a {
        background: $color-main;
        color: white;
        width: 100%;
        max-width: 100%;
        padding: 30px 0;
        @include view-at(sp) {
          padding: 25px 0;
        }
        &::after {
          color: white;
        }
      }
    }
  }
  &-article {
    h5 {
      font-size: 20px;
      color:$color-main;
      text-align: center;
      margin-bottom: 30px;
      font-weight: bolder;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      letter-spacing: 0.1em;

      @include view-at(sp) {
        margin-bottom: 20px;
      }
    }
    &-date {
      font-size: 14px;
      margin: 15px 0 8px;
      letter-spacing: 0.1em;
    }
    &-wrap {
      @include view-at(sp) {
        overflow-x: scroll;
      }
    }
    ul {
      @include view-at(sp) {
        width: 600px;
        margin-bottom: 20px;
      }
    }
  }

  // ランキング
  &-ranking {
    h3 {
      font-size: 22px;
      text-align: center;
      margin-bottom: 30px;
      color:$color-main;
    }

    &-btn {
      margin: 80px 0;
    }

    &-sec {
      margin: 80px 0;
    }

    &-list {
      @include view-at(sp) {
        width: 100%;
        margin: 0;
        display: block;
      }
      &-wrap {
        @include view-at(sp) {
          overflow: unset;
        }
      }

      &-item {
        width: calc(100%/2);
        @include view-at(sp) {
          width: calc(100%/1);
        }
        a {
          display: flex;
          flex-wrap: wrap;

        }
        &-img {
          width: 50%;
        }
        &-text {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:nth-child(1) {
          margin-left: 20%;
          width: 60%;
          @include view-at(sp) {
            margin-left: 0;
            width: 100%;
          }
          @include view-at(pc) {
            .top-Ranking-item-num {
              font-size: 42px;
            }
            .top-Ranking-item-ttl {
              font-size: 22px;
              margin-bottom: 15px;
            }
            .top-Ranking-item-price {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  //商品詳細ページ
  &-detail {
    
    h3 {
      font-size: 18px;
      text-align: center;
      line-height: 1.5em;
      color:$color-main;
      margin-bottom: 30px;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;

      @include view-at(pc) {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }
    &-top {
      margin-top: 0;
      position: relative;
      @include view-at(pc) {
        margin-top: 80px;
        margin-bottom: 0;
      }

      &-wrap {
        @include view-at(pc) {
          display: flex;
          margin-bottom: 40px;
        }
      }
      &-container {
        padding: 0;
      }
      &-img {
        margin-bottom: 80px;
        @include view-at(sp) {
          position: relative;
          padding-top: 55px;
        }

        @include view-at(pc) {
          width: 55%;
          margin-right: 80px;
        }

        .slick-dots {
          @include view-at(pc) {
            left: 0;
            width: auto;
          }
        }

        &-wrap {
          position: relative;
        }
      }
      &-tag {
        position: absolute;
        top: 15px;
        left: 15px;
        @include view-at(sp) {
          top: 5px;
          left: 5px;
        }

        span {
          height: 25px;
          @include view-at(sp) {
            font-size: 14px;
            height: 20px;
          }
        }
      }
      &-mark {
        position: absolute;
        bottom: 10px;
        right: 5px;

        @include view-at(pc) {
          bottom: -60px;
          display: flex;
          align-items: center;
          right: -10px;
        }

        li {
          text-align: center;
          padding: 10px;
          width: 52px;
          @include view-at(sp) {
            padding: 8px 10px;
          }
          
        }
        &-share {
          &:hover {
            cursor: pointer;
          }
        }
      }
      &-link {
        text-decoration: underline;
        color: $color-main;
        font-size: 20px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        line-height: 1.5em;
        @include view-at(sp) {
          font-size: 18px;
        }
      }
      &-cp {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #3081BE;
        padding: 20px;
        font-size: 13px;
        color:white;
        text-align: center;
        z-index: +1;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;

        a {
          color:white;
        }

        &::after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          content: '▼';
          display: inline-block;
          color: white;
          font-size: 8px;
        }
      }
    }

    &-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      @include view-at(pc) {
        justify-content: flex-start;
      }
      
      > div {
        padding:  0 2px;
      }
    }

    &-item {
      margin-bottom: 20px;

      @include view-at(sp) {
        padding: 0 20px;

        &.in {
          padding: 0;
        }
      }
      &-release {
        color: $color-main;
        letter-spacing: 0.2em;
        margin-bottom: 20px;
        @include view-at(sp) {
          text-align: center;
        }
      }
      &-ttl {
        font-size: 28px;
        margin-bottom: 20px;
        text-align: center;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        letter-spacing: 0.1em;
        line-height: 1.5em;

        @include view-at(pc) {
          text-align: left;
        }
        @include view-at(sp) {
          font-size: 22px;
        }
      }
      &-spec {
        font-size: 14px;
        margin-bottom: 15px;
        @include view-at(pc) {
          justify-content: flex-start;
        }
        @include view-at(sp) {
          text-align: center;
        }
      }
      &-price {
        font-size: 18px;
        margin-bottom: 20px;
        @include view-at(sp) {
          text-align: center;
        }
      }
      &-tel {
        a {
          border: none;
        }
      }
    }

    &-color {
      border: 1px solid $color-gray-light;
      border-radius: 10px;
      margin-bottom: 30px;
      @include view-at(sp) {
        margin-bottom: 20px;
      }

      &-ttl {
        font-size: 14px;
        text-align: center;
        padding: 10px;
        border-bottom: 1px solid $color-gray-light;
      }
      &-select {
        position: relative;
        padding: 10px;

        &-img {
          width: 15%;
          padding-right: 10px;
        }
        &-text {
          width: 85%;
          font-size: 14px;
        }
      }

      .display_selected {
        &-wrap {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 10px;
          position: relative;

          &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            content: '▼';
            display: inline-block;
            font-size: 8px;
          }
        }
        &Item {
          width: 85%;
          &-img {
            width: 15%;
            padding-right: 10px;
          }
        }
      }
      .select_box {
          width: 100%;
          border: 1px solid $color-gray-light;
          filter: drop-shadow(0 0 5px $color-gray-light);
          position: absolute;
          left: 0;
          top: 95%;
          z-index: +1;
          background: white;

          li {
            cursor: default;
            box-sizing: border-box;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 14px;
            width: 100%;
          }
      }
    }

    &-copy {
      font-size: 14px;
      line-height: 1.8em;
      @include view-at(pc) {
        font-size: 16px;
      }
    }

    &-btn {
      padding: 10px 0 0;
      width: 100% !important;
      @include view-at(pc) {
        padding: 0;
      }

      a {
        padding: 20px;
        text-align: center;
        font-size: 20px;
        background: $color-main;
        display: block;
        color: white;
        letter-spacing: 0.1em;
        position: relative;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;

        span {
          small {
            font-size: 12px;
            display: block;
            text-align: center;
            letter-spacing: 0.2em;
            margin-bottom: 5px;
          }
        }

        &::after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          content: '▶';
          display: inline-block;
          color: $color-white;
          font-size: 8px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
          width: 40px;
          height: 40px;
          background: url(../../img/usr/products/btn-cart.png) no-repeat center;
          background-size: 100%;
        }

        &.regular {
          background: #3081BE;
          &::before {
            background: url(../../img/usr/products/btn-regular.png) no-repeat center;
            background-size: 100%;
          }
        }


      }
      
    }

    &-copy {
      margin-bottom: 30px;

      h3 {
        font-size: 20px;
        color: $color-main;
        line-height: 1.8em;
        margin-bottom: 10px;
        text-align: left;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        @include view-at(pc) {
          font-size: 20px;
        }
      }

      p {
        font-size: 14px;
        line-height: 1.8em;
        margin-bottom: 10px;
      }
    }

    &-point {
      h4 {
        font-size: 18px;
        color: #294C74;
        line-height: 1.8em;
        margin-bottom: 30px;
        text-align: left;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        @include view-at(pc) {
          font-size: 20px;
        }
      }
      h5 {
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        @include view-at(pc) {
          font-size: 16px;
        }
      }
      ul {
        @include view-at(pc) {
          display: flex;
          flex-wrap: wrap;
          margin: -10px;
        }
        li {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          @include view-at(pc) {
            display: block;
            width: 50%;
            padding: 10px;
          }
        }
      }

      &-img {
        position: relative;
        padding-right: 15px;
        width: 55%;
        @include view-at(pc) {
          padding-right: 0;
          width: 100%;
        }

        span.num {
          background: $color-main;
          color: white;
          position: absolute;
          top: 0;
          left: 0;
          padding: 5px 8px;
        }
      }
      &-text {
        width: 45%;
        @include view-at(pc) {
          width: 100%;
        }
        .ttl {
          font-size: 14px;
          color: $color-main;
          margin-bottom: 5px;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          @include view-at(pc) {
            font-size: 16px;
            margin-top: 5px;
          }
        }
        .text {
          font-size: 12px;
          line-height: 1.5em;
          @include view-at(pc) {
            font-size: 14px;
          }
        }
      } 
      
    }

    &-component {
      h4 {
        font-size: 18px;
        color: #294C74;
        line-height: 1.8em;
        margin-bottom: 30px;
        text-align: left;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        @include view-at(pc) {
          font-size: 20px;
        }
      }
      h5 {
        background: $color-gray-snow;
        text-align: center;
        padding: 8px;
        margin-bottom: 8px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        @include view-at(pc) {
          font-size: 16px;
        }
      }
      ul {
        li {
          margin-bottom: 20px;
          p {
            font-size: 12px;
            @include view-at(pc) {
              font-size: 14px;
            }
            &.cap {
              font-size: 8px;
              line-height: 1.8em;
              @include view-at(pc) {
                font-size: 10px;
              }
            }
          }
          
        }
      }
    }

    &-osusume {
      padding: 30px 20px 20px;
      border-radius: 10px;
      border: 1px solid $color-main;
      position: relative;
      @include view-at(pc) {
        padding: 60px;
        border-radius: 30px;
      }

      h3 {
        text-align: center;
        position: absolute;
        top: -15px;
        left: 50%;
        width: 75%;
        transform: translateX(-50%);
        background: white;
        @include view-at(pc) {
          width: 40%;
        }
      }

      tr {
        color: $color-main;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        &:nth-last-child(1) {
          margin-bottom: 0;
          @include view-at(sp) {
            margin-bottom: 5px;
          }
        }

        th {
          font-size: 22px;
          padding-right: 10px;
          width: 30px;
          border-right: 1px solid;
          margin-right: 0;
          text-align: center;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          @include view-at(pc) {
            width: 40px;
            text-align: center;
          }
        }
        td {
          font-size: 14px;
          line-height: 1.5em;
          padding-left: 15px;
          width: 90%;
          @include view-at(pc) {
            font-size: 14px;
            padding-left: 20px;
          }
        }
      }
    }

    &-review {
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
    }

    &-popular {
      @include view-at(sp) {
        overflow-x: scroll;
        overflow-y: hidden;

        &-list {
          width: 800px;
          margin-bottom: 20px;
          li {
            width: 25% !important;
          }
        }
      }
    }

    &-cart {
      &-inner {
        background: $color-main;
        height: auto;
        padding: 60px 40px;
      }

      &-text {
        font-size: 16px;
        margin-bottom: 30px;
        color: white;
        text-align: center;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        letter-spacing: 0.1em;
        @include view-at(pc) {
          font-size: 18px;
        }
      }
      &-close {
        position: absolute;
        top: -40px;
        right: 0;
        font-size: 30px;
        color: $color-main;
        font-weight: bold;
      }
      &-btns {
        @include view-at(pc) {
          display: flex;
          justify-content: center;
        }
        a {
          max-width: 180px;
          margin: 0 auto;
          background: #3081BE;
          color: white;
          padding: 10px 20px 13px;
          text-align: center;
          display: block;
          margin-bottom: 15px;
          border-radius: 20px;
          line-height: 1;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;

          @include view-at(pc) {
            width: 300px;
            max-width: 300px;
            padding: 20px;
            border-radius: 40px;
            margin: 0;
            margin: 0 10px;
          }

          &:nth-last-child(1) {
            margin-bottom: 0;
            background: white;
            color: $color-text;
          }
        }
      }

    }

    &-cv {
      &-btn {
        display: block;
        position: fixed;
        bottom: 63px;
        right: 4px;
        width: 50px;
        z-index: +1;
        @include view-at(pc) {
          bottom: 10px;
          right: 10px;
          width: 70px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }

    &-share {
      &-inner {
        background: #3081BE;
        height: auto;
        padding: 80px 40px;
      }

      &-text {
        font-size: 16px;
        margin-bottom: 30px;
        color: white;
        text-align: center;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        letter-spacing: 0.1em;
        @include view-at(pc) {
          font-size: 18px;
        }
      }
      &-close {
        position: absolute;
        top: -40px;
        right: 0;
        font-size: 30px;
        color: $color-main;
        font-weight: bold;
      }
      ul {
        display: flex;
        justify-content: center;

        li {
          padding: 0 20px;
          @include view-at(pc) {
            padding: 0 30px;
          }
          a {
            display: block;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-height: 100%;
            }
          }
        }
      }

    }

    &-regular {
      background: $color-gray-snow;
        padding: 20px;
        text-align: center;
        position: relative;
        margin-top: 30px;

        &::before {
          content: "";
          position: absolute;
          top: -30px;
          left: 50%;
          margin-left: -15px;
          border: 15px solid transparent;
          border-bottom: 15px solid $color-gray-snow;
        }
        h5 {
          font-size: 20px;
          text-align: center;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          line-height: 2em;
          color: $color-main;
          border-bottom: 1px solid;
          margin-bottom: 10px;
          letter-spacing: 0.1em;
          @include view-at(sp) {
          }
        }
        p {
          font-size: 12px;
          line-height: 1.8em;
          margin-bottom: 10px;
        }
        ul {
          display: flex;
          margin-bottom: 10px;
          flex-wrap: wrap;
          justify-content: center;
          @include view-at(sp) {
            margin: -5px;
            margin-bottom: 5px;
          }
          li {
            padding: 5px;
            width: calc(100%/3);
          }
        }
        a {
          text-align: center;
          text-decoration: underline;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          color: $color-main;
          @include view-at(sp) {
            font-size: 12px;
          }
        }

      &02 {
        background: #e0ecf5;
        padding: 20px;
        text-align: center;
        position: relative;
        margin-top: 30px;

        &::before {
          content: "";
          position: absolute;
          top: -30px;
          left: 50%;
          margin-left: -15px;
          border: 15px solid transparent;
          border-bottom: 15px solid #e0edff;
        }
        h5 {
          font-size: 20px;
          text-align: center;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          line-height: 2em;
          color: $color-main;
          border-bottom: 1px solid;
          margin-bottom: 10px;
          letter-spacing: 0.1em;
          @include view-at(sp) {
          }
        }
        p {
          font-size: 12px;
          line-height: 1.8em;
          margin-bottom: 10px;
        }
        ul {
          display: flex;
          margin-bottom: 10px;
          li {
            padding: 5px;
          }
        }
        a {
          text-align: center;
          text-decoration: underline;
        }
      }
    }

    &-step {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border: 1px solid $color-gray-light;
      img {
        width: 30%;
        @include view-at(sp) {
          width: 35%;
        }
      }
      &-text {
        width: 70%;
        text-align: center;
        font-size: 28px;
        line-height: 1.5em;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        color: $color-main;
        letter-spacing: 0.1em;

        @include view-at(sp) {
          font-size: 14px;
          width: 65%;
        }
      }
    }

    .keywords {
      h5 {
        background: $color-main;
        color: white;
        text-align: center;
        padding: 8px;
        margin-bottom: 8px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        font-size: 20px;
        @include view-at(pc) {
          
        }
      }
      ul {
        display: flex;
        li {
          padding: 0 5px;
          font-size: 12px;
          @include view-at(pc) {
            font-size: 14px;
          }
          &::after {
            content: "/";
            padding-left: 10px;
          }

          &:nth-last-child(1) {
            &::after {
              content: "";
            }
          }

          a {
            color: $color-main;
            text-decoration: underline;
          }
        }
      }
    }
  }

  //商品検索
  &-serch {
    &-btn {
      margin: 20px 0;
      @include view-at(pc) {
        margin: 60px 0;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
        @include view-at(pc) {
          margin: -15px;
        }

        li {
          padding: 5px;
          width: 50%;
          @include view-at(pc) {
            padding: 15px;
            width: 25%;
          }

          a {
            display: block;
            background: $color-gray-snow;
            padding: 10px;
            padding-bottom: 20px;
            border: 1px solid $color-gray-light;
            border-radius: 10px;
            text-align: center;
            font-size: 14px;
            line-height: 1.4em;
            color: $color-main;
            position: relative;
            font-weight: 600;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            @include view-at(pc) {
              padding: 15px;
            }
            strong {
              font-size: 18px;
            }

            &::after {
              content: '';
              width: 5px;
              height: 5px;
              border: 0px;
              border-bottom: solid 1px $color-main;
              border-right: solid 1px $color-main;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              bottom: 10px;
              left: 46%;

              @include view-at(pc) {
                left: auto;
                right: 20px;
                bottom: auto;
                top: 45%;
              }
            }
          }

          &:nth-last-child(1) {
            a {
              background: white;
              padding-bottom: 15px;
              padding-top: 15px;

              &::after {
                -webkit-transform: rotate(315deg);
                transform: rotate(315deg);
                top: 45%;
                right: 20px;
                left: unset;
              }
            }
          }
        }
      }
    }

    &-input {
      background: $color-gray-snow;
      padding: 20px 0;
      margin: 0;
      font-family: 'Noto Sans JP';
      font-weight: 300;
      @include view-at(pc) {
        max-width: $contents-width;
        margin: 0 auto;
        padding: 40px;
        text-align: center;
      }

      form {
        position: relative;
      }

      .candidate {
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        max-width: 380px;
        z-index: +1;
        filter: drop-shadow(0px 0px 4px $color-gray-light);

        &-ttl {
          background: $color-gray-snow;
          padding: 15px 20px;
          color: $color-main;
          text-align: left;
          font-size: 14px;
          letter-spacing: 0.1em;
          font-weight: 500;
          line-height: 1;
        }
        &-inner {
          display: none;
          background: white;
          z-index: +1;
          border: 1px solid $color-gray-snow;
          &.is-open {
            display: block;
          }

          ol {
            padding-top: 10px;
            li {
              padding: 10px;
              border-bottom: 1px solid $color-gray-snow;

              a {
                display: block;
                display: flex;
              }
            }
          }
        }
        &-bg {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: none;
          &.is-open {
            display: block;
          }
        }
        &-img {
          width: 50px;
        }
        &-text {
          margin-left: 20px;
          p {
            font-size: 14px;
            margin-bottom: 0;
            text-align: left;
            letter-spacing: 0.1em;
          }
        }
        dl {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          position: relative;
          dt {
            width: 15px;
            height: 15px;
            background: url(../../img/usr/products/serch-bk.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 3px;
            left: 10px;
          }
          dd {
            font-size: 12px;
            width: 100%;
            padding-left: 40px;
            text-align: left;
            line-height: 1.5em;
          }
        }
        &-keyword {
          margin-bottom: 10px;
          padding-right: 10px;
          li {
            border: none !important;
          }
        }
      }
      &-word {
        display: flex;
        margin-bottom: 10px;
        background: white;
        align-items: center;
        position: relative;
        @include view-at(pc) {
          max-width: 600px;
          margin: 0 auto 20px;
        }
        .word {
          width: 100%;
          border: none;
          height: 60px;
          padding: 10px 20px;
          margin-right: 60px;
          z-index: +1;
          &:focus {
            outline: none;
            border: none;
          }
          &:hover {
            border: none;
          }
          
          &-btn {
            width: 20px;
            height: 20px;
            background: url(../../img/usr/products/serch-bk.png) no-repeat;
            background-size: 100%;
            border: none;
            margin-right: 20px;
            position: absolute;
            right: 0;
            z-index: +1;
            cursor: pointer;
            &:focus {
              outline: none;
              border: none;
            }
          }
        }
      }
     
      &-tag {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          border: 2px solid $color-main;
          border-radius: 20px;
          padding: 3px;
          text-align: center;
          font-size: 14px;
          margin: 3px 0.5%;
          width: 24%;
          font-family: 'Noto Sans JP';
          font-weight: 500;
          @include view-at(pc) {
            max-width: 100px;
            width: auto;
            padding: 5px 10px;
            margin: 0 5px;
          }

          a {
            color: $color-main;
          }
        }
      }
    }

    &-link {
      
      ul {
        display: flex;
        justify-content: center;
        max-width: 800px;
        margin: 0 auto;
        @include view-at(sp) {
          display: block;
          margin: -10px;
        }

        li {
          padding: 20px;
          @include view-at(sp) {
            padding: 10px;
            text-align: center;
          }
        }
      }
    }

    &-item {
      margin: 140px 0;
      @include view-at(sp) {
        margin: 80px 0 60px;
      }
    }
  }

  //シリーズ
  &-series {
    &-top {
      margin: 0 0 20px;
      
      @include view-at(pc) {
        max-width: $contents-width;
        margin: 40px auto;
        padding: 0 $contents-padding;
      }
      &-wrap {
        @include view-at(pc) {
          background: $color-gray-snow;
          padding: 20px;
          display: flex;
          align-items: flex-start;
        }
        
      }
      &-text {
        margin: 20px;
        @include view-at(pc) {
          margin: 0;
        }
      }
    }
    &-text {
      padding: 20px;
      background: $color-gray-snow;
      margin-top: 20px;
      text-align: center;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      @include view-at(pc) {
        text-align: left;
        margin: 0;
        padding: 20px;
        padding-left: 40px;
      }

      h4 {
        font-size: 16px;
      }
    }
    &-btn {
      &-wrap {
        max-width: 800px;
        margin: 0 auto;
      }
      li {
        a {
          background: $color-gray-snow;
          color: $color-text;
          border: none;
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          align-items: center;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          @include view-at(pc) {
            display: block;
          }

          > div {
            width: 45%;
            font-size: 18px;
            text-align: left;
            letter-spacing: 0.1em;
            @include view-at(pc) {
              width: 100%;
              line-height: 1.3em;
            }
            small {
              font-size: 10px;
              @include view-at(pc) {
                font-size: 14px;
              }
            }
            &:nth-child(2) {
              width: 55%;
              padding-left: 20px;
              @include view-at(pc) {
                width: 100%;
                padding: 0;
                text-align: center;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

#detail {
  .p-header-nav > ul li {

    @include view-at(sp) {
      &:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5) {
        display: none;
      }
      &.purchase {
        display: block;
        width: 80%;
        padding: 5px;
        
  
        a {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background: $color-main;
          
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          span {
            margin-left: 30px;
            letter-spacing: 0.15em;
            font-size: 20px;
          }
          img {
            width: 50px;
          }
        }

        &-p2 {
          display: none;
        }
      }
    }
    
  }
  &.p2 {
    .p-header-nav > ul li {

      @include view-at(sp) {
        &.purchase {
          display: none;
          &-p2 {
            display: block;
            width: 80%;
            padding: 5px;
    
            a {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              background: $color-main;
              
              font-family: 'Tenor Sans', 'Noto Serif JP', serif;
              span {
                margin-left: 30px;
                letter-spacing: 0.15em;
                font-size: 20px;
              }
              img {
                width: 50px;
              }
            }
          }
        }
      }
    }
  }
}



