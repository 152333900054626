
html.is-fixed,
html.is-fixed body {
  @include view-at(sp) {
    overflow: hidden;
  }
}

body {
  font-family: 'Noto Sans JP';
  font-weight: 400;
  color: $color-text;
  letter-spacing: 0.1em;
}

a {
  color: $color-text;
  text-decoration: none;
  transition: all 0.5s ease 0s;

  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }
}

p {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
    margin-bottom: 10px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

th {
  font-weight: 300;
}

article {
  overflow: hidden;
  
  @include view-at(pc) {
    margin-top: 0;
  }
}

.page {
  &-mv {
    border-bottom: 1px solid #E6E6E6;
    padding: 60px 0;

    @include view-at(pc) {
      padding: 80px 0;
    }
      &-text {
        padding: 0 20px;
          .ttl {
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            letter-spacing: 0.1em;
            text-align: center;
            font-size: 16px;

            @include view-at(pc) {
              font-size: 24px;
            }

            &.big {
              font-size: 22px;
              @include view-at(pc) {
                font-size: 34px;
              }
            }
          }
          .sub-ttl {
            font-size: 14px;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            letter-spacing: 0.1em;
            text-align: center;
            margin-bottom: 20px;
          }
          .text {
            font-size: 14px;
            text-align: center;
            line-height: 1.5em;
            margin-top: 20px;
          }
          .link {
            text-align: center;
            margin-top: 20px;

            a {
              position: relative;
              padding: 0 30px;
              text-decoration: underline;
              letter-spacing: 0.1em;

              &::after {
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                right: 5px;
                content: '〉';
                display: inline-block;
                color: white;
                font-weight: bold;
              }
            }
          }
      }
  }
}

.rel {
  position: relative;
}
