.modal{
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  left: 0;
}
.modal__bg{
  background: rgba($color-gray-snow, 0.8);
  height: 100vh;
  position: absolute;
  width: 100%;
}
.modal__content{
  background: #fff;
    left: 50%;
    padding: 10px;
    padding-bottom: 20px;
    position: absolute;
    top: 49%;
    transform: translate(-50%,-50%);
    width: 90%;
    position: relative;
    height: 85%;
  
  @include view-at(pc) {
    max-width: 1000px;
    height: auto;
    max-height: 90%;
    padding: 30px;
    top: 50%;
  }
  @include view-at(tab) {
    background: #fff;
    left: 50%;
    padding: 10px;
    padding-bottom: 20px;
    position: absolute;
    top: 49%;
    transform: translate(-50%,-50%);
    width: 90%;
    position: relative;
    height: 85%;
  }

  &-wrap {
    height: 100%;
    overflow: hidden;
  }

  .ttl {
    font-size: 16px;
    padding: 20px;
    background: $color-main;
    color: white;
    text-align: center;
    letter-spacing: 0.1em;
    font-family: 'Tenor Sans', 'Noto Serif JP', serif;
    font-weight: 400;

    @include view-at(pc) {
      font-size: 17px;
      margin-bottom: 20px;
    }
  }

  .model-wrap {
    height: 90%;
    padding: 0 10px;
    margin: 20px 0 0;
    @include view-at(tab) {
      overflow-y: scroll;
    }

    @include view-at(pc) {
      height: auto;
      display: flex;
      padding: 0;
      margin: -10px;
    }

    .item {
      margin-bottom: 20px;

      @include view-at(pc) {
        padding: 10px;
        width: 20%;
      }

      h3 {
        font-size: 15px;
        padding: 15px;
        margin-bottom: 15px;
        background: $color-gray-snow;
        color: $color-main;
        text-align: left;
        text-align: center;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        font-weight: 400;

        @include view-at(pc) {
          font-size: 14px;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        margin: -7px 0;

        li {
          width: 50%;
          padding: 7px 0;
          font-size: 14px;
          position: relative;
          padding-left: 10px;
          letter-spacing: 0;

          @include view-at(pc) {
            font-size: 13px;
            padding: 10px;
            padding-left: 20px;
            width: 100%;
          }
          @include view-at(se) {
            font-size: 11px;
          }

          &::after {
            content: "";
            border-top: 1px solid;
            width: 6px;
            position: absolute;
            left: 0;
            top: 50%;

            @include view-at(pc) {
              width: 10px;
            }
          }
        }

        &.one {
          li {
            width: 100%;
          }
        }
      }

      &:nth-child(1) {
        @include view-at(pc) {
          width: 40%;

          ul {
            li {
              width: 50%;
            }
          }
        }
      }
    }
  }

  a {
    text-decoration: none;

    &.js-modal-close.c-button--border{
      font-size: 14px;
      border-color: $color-gray-light;
      padding: 10px 30px;
      
      @include view-at(pc) {
        display: none;
      }
    }
  }
}
.top-close {
  position: absolute;
  top: -6%;
  right: 0;
  color: #294C74;
  font-size: 30px;
  font-weight: bold;

  @include view-at(pc) {
    top: -40px;
  }
}
