/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 17px;
  display: block;


  @include view-at(pc) {
    display: none;
  }

  &-trigger,
  &-trigger span {
    display: inline-block;
    transition: all .2s;
    box-sizing: border-box;
  }

  &-trigger {
    &-wrap {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: white;
      padding: 10px 20px;
      z-index: 99;
      text-align: right;
    }
    position: relative;
    width: 25px;
    height: 25px;

    &-label {
      display: block;
      font-size: 8px;
      letter-spacing: 1.1px;
      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      margin: auto;
      text-align: center;
      width: 100%;
      background-color: transparent;
      height: auto;
      color: $color-white;
    }
  }

  &-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1.5px;
    background-color: #4c4948;
  }
  
  &-trigger span:nth-of-type(1) {
    top: 4px;
  }

  &-trigger span:nth-of-type(2) {
    top: 12px;
  }

  &-trigger span:nth-of-type(3) {
    bottom: 3px;
  }

  &-trigger.close {
    z-index: 9999;
    width: 28px;

    span {
      height: 2px;
    }
  }

  &-trigger.close span:nth-of-type(1) {
    -webkit-transform: translateY(8px) translateX(0px) rotate(-45deg);
    transform: translateY(8px) translateX(0px) rotate(-45deg);
  }

  &-trigger.close span:nth-of-type(2) {
    opacity: 0;
  }

  &-trigger.close span:nth-of-type(3) {
    -webkit-transform: translateY(-8px) translateX(0px) rotate(45deg);
    transform: translateY(-8px) translateX(0px) rotate(45deg);
  }

  &-recruit {
    right: 20px;
  }
}
