
.p-exnav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $color-white;
  color: white;
  overflow: scroll;
  height: 100vh;
  z-index: 9999;

  &-inner {
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 0;

    h4 {
      color: $color-main;
      text-align: center;
      margin-bottom: 10px;
      font-size: 22px;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      letter-spacing: 0.1em;

      &.en {
        font-size: 27px;
      }

      span {
        display: block;
        margin-top: 10px;
        font-size: 12px;
        letter-spacing: 0.1em;
      }
    }
  }
  &-btn {
    margin-bottom: 40px;
    font-family: 'Tenor Sans', 'Noto Serif JP', serif;

    li {
      background: $color-main;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        display: block;
        position: relative;
        color: white;
        font-size: 16px;
        letter-spacing: 0.1em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 100%;
        
        text-align: center;

        &::after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          content: '▶';
          display: inline-block;
          color: $color-white;
          font-weight: bold;
          font-size: 8px;
        }

        > img {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          width: 25px;
        }

      }
      &:nth-child(2) {
        background: #3081BE;
      }

      &:nth-last-child(1) {
        background: $color-gray-snow;
        font-family: 'Noto Sans JP';
        
        a {
          padding: 0;
          flex-wrap: wrap;
          color: $color-text;
          &::after {
            color: $color-text;
          }

          > div {
            width: 30%;

            &:nth-last-child(1) {
              width: 70%;
            }
          }
          img {
            padding: 0;
          }
        }
      }

    }
  }

  &-products {
    margin-bottom: 20px;

    li {
      border-top: 1px solid $color-gray-snow;

      &:nth-last-child(1) {
        border-bottom: 1px solid $color-gray-snow;
      }
      a {
        padding: 15px;
        display: block;
        position: relative;
        font-size: 14px;
        letter-spacing: 0.1em;

        &::after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
          content: '〉';
          display: inline-block;
          color: #1A556F;
          font-weight: bold;
        }
      }
    }
  }

  &-trial {
    margin-bottom: 40px;
    background: $color-gray-snow;
    font-family: 'Noto Sans JP';
        
        a {
          padding: 0;
          flex-wrap: wrap;
          color: $color-text;
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            color: $color-text;
          }

          > div {
            width: 30%;

            &:nth-last-child(1) {
              width: 70%;
              text-align: center;
              color: #294C74;
              font-size: 17px;
              font-family: 'Tenor Sans', 'Noto Serif JP', serif;
              line-height: 1.5;
              small {
                color: gray;
                display: block;
                line-height: 1.5;
              }
            }
          }
          img {
            padding: 0;
          }
        }
  }

  &-contact {
    padding: 30px 0;
    border-bottom: 1px solid $color-gray-snow;
    display: block;
    color: #00425F;
    text-align: center;
    margin-bottom: 30px;

    h4 {
      margin-bottom: 15px;
    }

    &-tel {
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      font-size: 32px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.1em;
      color: #3081BE;

      img {
        padding-right: 10px;
      }
    }
    &-time {
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.1em;
    }
  }

  &-other {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    li {
      padding: 0 20px;

      a {
        border-bottom: 1px solid $color-gray-light;
        font-size: 14px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      }
    }
  }

  &-sns {
    background: #3081BE;
    margin-bottom: 20px;
    margin: 0 -20px;
    padding: 40px 0;

    > h4 {
      color: white;
      font-size: 14px;
      margin-bottom: 20px;
    }

    ul {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      li {
        padding: 0 15px;

        a {
          img {
            max-height: 25px;
          }
        }
      }
    }
    p {
      text-align: center;
      font-size: 14px;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      letter-spacing: 0.1em;
      
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        span {
          display: block;
          line-height: 1;
  
          img {
            max-height: 15px;
            padding-right: 10px;
          }
        }
      }
    }
  }

  &-next {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    padding: 20px;
    padding-bottom: 40px;
    display: none;
    overflow-y: scroll;
    height: 100vh;
    z-index: 9999999999;

    &.active {
      display: block;
    }

    &-inner {
      padding-top: 50px;
    }

    h4 {
      color: $color-main;
      text-align: center;
      margin-bottom: 30px;
      font-size: 18px;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      letter-spacing: 0.1em;
    }

    h3 {
      font-size: 18px !important;
    }

    &-ttl {
      font-size: 12px !important;
      line-height: 1.5em;

      &.target {
        border: 1px solid $color-gray-light;
        padding: 20px;
        margin: 0;
        margin-bottom: 5px;
      }
    }
    &-img {
      padding: 0 !important;
    }
  }
}
