
/* --------------------------
color
-------------------------- */
$color-text: #666464;
$color-text-gray: #989898;
$color-bg: #F7F7F7;
$color-main: #294C74;
$color-sub: #AFC7C9;

$color-red: #e20013;
$color-red-dark: #D04255;
$color-red-light: #f8ebed;
$color-blue: #009dbf;
$color-blue-dark: #0b2b75;
$color-blue-light: #e8f5f3;
$color-gold: #dbc583;
$color-gold-light: #ede2c1;
$color-orange: #ff6c11;
$color-pink: #f8ebed;
$color-green: #009940;
$color-green-light: #d1f2ec;
$color-white: #fff;
$color-black: #231815;

$color-gray: #8E8E8E;
$color-gray-light: #D0D0D0;
$color-gray-dark: #717171;
$color-gray-snow: #F0F0F0;


/* --------------------------
spacing
-------------------------- */
$space: 80px, 60px, 30px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 120px, 80px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 32px, 22px, 20px, 18px, 16px, 14px, 12px;
$line-height: 68px, 30px, 30px, 22px, 20px, 18px;

$font-size-sp: 32px, 20px, 18px, 16px, 14px, 12px;
$line-height-sp: 40px, 36px, 32px, 22px, 16px, 16px;

$bold: 400;
$normal: 300;

$text-go: 'Noto Sans JP', sans-serif;
$text-min: 'Noto Serif JP', serif;

/* --------------------------
contents width
-------------------------- */
$contents-width: 1080px;
$contents-middle-width: 750px;
$contents-narrow-width: 600px;
$contents-padding: 20px;
$contents-padding-sp: 20px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  header,
  footer,
  modal
);
