
.p-footer {
  text-align: center;
  padding: 40px 0 50px;
  font-family: 'Tenor Sans', 'Noto Serif JP', serif;
  font-weight: 400;
  border-top: 1px solid $color-gray-light;

  @include view-at(sp) {
    
  }

  &-sns {
    margin-bottom: 40px;

    h4 {
      font-size: 14px;
      letter-spacing: 0.2em;
    }
    ul {
      display: flex;
      justify-content: center;

      li {
        padding: 20px 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          width: 25px;
          display: block;
          &.fa {
            width: 13px;
          }
        }
      }
    }
    &-mail {
      a {
        font-size: 14px;
        letter-spacing: 0.1em;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          line-height: 0;
          padding: 0 5px;
        }
      }
    }
  }

  &-link {
    margin: -10px;
    display: flex;
    flex-wrap: wrap;
    @include view-at(tab) {
      margin: -5px;
    }

    li {
      padding: 10px;
      width: calc(100%/4);
      @include view-at(tab) {
        padding: 5px;
        width: calc(100%/2);
      }

      a {
        display: block;
        text-align: center;
        border:1px solid;
        padding: 20px;
        font-size: 14px;
        letter-spacing: 0.1em;
        cursor: pointer;

        @include view-at(sp) {
          padding: 10px;
        }

        span {
          font-size: 12px;
          display: block;
          margin-top: 5px;
          letter-spacing: 0.15em;

          @include view-at(sp) {
            letter-spacing: 0em;
            font-size: 12px;
            margin: 0;
            padding: 8px 0;
          }
        }
      }
    }
  }

  &-nav {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    margin: 20px auto 40px;

    li {
      padding: 10px;
      width: calc(100%/4);
      text-align: left;
      font-size: 14px;
      @include view-at(sp) {
        width: calc(100%/2);
      }
      
      a {
        display: block;
        cursor: pointer;
      }
    }
  }

  &-logo {
    display: block;
    margin-bottom: 40px;
  }

  &-gnav {
    display: flex;
    justify-content: left;
    padding-right: 20px;

    @include view-at(tab) {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 20px;
    }

    li {
      @include view-at(sp) {
        padding: 5px 0;
      }

      a {
        letter-spacing: 0.1em;
        border-right: 1px solid;
        padding: 0 10px;
        font-size: 12px;
        cursor: pointer;
      }

      &:nth-last-child(1) {
        a {
          border:none;
        }
      }
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include view-at(sp) {
      display: block;
      margin-bottom: 60px;
    }
  }
  
  &-copylight {
    font-size: 11px;
    font-family: 'Tenor Sans', 'Noto Serif JP', serif;
    letter-spacing: 0.1em;
  }

  &-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    background: $color-gray-dark;

    @include view-at(sp) {
      bottom: 65px;
      right: 5px;
    }

    span {
      position: relative;
      display: block;
      width: 40px;
      height: 40px;

      &::after {
        content: '';
        width: 12px;
        height: 12px;
        border: 0px;
        border-bottom: solid 2px white;
        border-right: solid 2px white;
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
        position: absolute;
        top: 45%;
        left: 36%;
      }
    }
  }
}
