.mypage {
    h2 {
        font-size: 27px;
        letter-spacing: 0.2em;
        margin-bottom: 40px;
        text-align: center;
        @include view-at(sp) {
            margin-bottom: 30px;
        }

        &.bule {
            color: $color-main;
            @include view-at(sp) {
                font-size: 18px;
            }
        }
    }
    .c-section {
        margin-bottom: 140px;
        @include view-at(sp) {
            margin: 80px 0;
        }
    }

    &-message {
        @include view-at(sp) {
            margin: 40px 0 !important;
        }
    }
    &-mv {
        background: url(../../img/usr/mypage/mv.png) no-repeat;
        background-size: cover;
        color: white;

        a {
            color: white;
        }
    }

    &-top {
        margin-top: 70px;
        @include view-at(sp) {
        }

        &-wrap {
            margin-bottom: 40px;
            font-size: 27px;
            text-align: center;
            @include view-at(sp) {
                font-size: 22px;
                margin-bottom: 20px;
            }
        }
        &-name {
            letter-spacing: 0.2em;
            margin-bottom: 15px;
        }
        &-message {
            letter-spacing: 0.2em;
            line-height: 1.5em;
        }
        &-point {
            border: 2px solid $color-gray-light;
            padding: 40px;
            text-align: center;
            @include view-at(sp) {
                padding: 20px 20px 30px;
            }

            &-now {
                color: $color-main;
                text-align: center;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0;

                span {
                    letter-spacing: 0.2em;
                    &:nth-child(1) {
                        padding-right: 10px;
                    }
                   &.point {
                       font-size: 44px;
                       font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                       letter-spacing: 0.1em;
                       &-p {
                        font-size: 33px;
                        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                        letter-spacing: 0.1em;
                       }
                   }
                }
            }
            &-text {
                font-size: 14px;
                text-align: center;
                line-height: 1.8em;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    font-size: 12px;
                    margin-bottom: 20px;
                }
            }
            &-stage {
                text-align: center;
                margin-bottom: 30px;
                
                p {
                    display: inline-block;
                    background: #F2F2F2;
                    padding: 20px 30px;
                    font-size: 14px;
                    text-align: center;
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        display: block;
                        width: 0;
                        height: 0;
                        right: 20px;
                        top: -15px;
                        border-right: 15px solid transparent;
                        border-bottom: 15px solid #F2F2F2;
                      }
                }
            }
            &-link {
                position: relative;
                padding: 0 30px;
                text-decoration: underline;
                letter-spacing: 0.1em;

                &::after {
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    right: 5px;
                    content: '〉';
                    display: inline-block;
                    font-weight: bold;
                }
            }
            &-progress {
                max-width: 450px;
                width: 100%;
                margin: 0 auto 20px;
            }
        }
        &-text {
            font-size: 14px;
            text-align: center;
            line-height: 1.8em;
            margin-bottom: 40px;
        }
    }

    &-menu {
        
        ul {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: -10px;
            @include view-at(sp) {
                margin: -5px;
            }

            li {
                width: 20%;
                padding: 10px;
                @include view-at(sp) {
                    width: calc(100%/3);
                    padding: 5px;
                }
            }
        }
    }

    &-regular {
        background: $color-gray-snow;

        @include view-at(sp) {
            padding: 40px 20px;
        }

        h2 {
            color: $color-main;
            @include view-at(sp) {
                font-size: 20px;
                margin-bottom: 20px !important;
            }
        }

        &-date {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 40px;
            @include view-at(sp) {
                align-items: center;
            }

            &-img {
                margin-right: 30px;
                @include view-at(sp) {
                    width: 39%;
                    margin: 0;
                    padding-right: 6%;
                }
            }
            &-text {
                @include view-at(sp) {
                    width: 55%;
                }
                p {
                    color: $color-main;
                    font-size: 17px;
                    margin-bottom: 5px;
                    font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                    letter-spacing: 0.15em;
                    @include view-at(sp) {
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                    span {
                        font-size: 27px;
                        letter-spacing: 0.15em;
                        @include view-at(sp) {
                            font-size: 22px;
                        }
                    }
                }
            }
        }

        &-detail {
            font-size: 14px;
            color: $color-main;
            @include center-line();
            margin-bottom: 5px;
            letter-spacing: 0.2em;
            margin-bottom: 30px;

            &::before,
            &::after {
                background-color: $color-main;
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: -20px -20px 20px;
            @include view-at(sp) {
                margin: -5px;
                margin-bottom: 20px;
            }

            li {
                padding: 20px;
                width: calc(100% /3);
                @include view-at(sp) {
                    width: calc(100% /1);
                    padding: 5px;
                }
            }
        }
        &-block {
            background: white;
            padding: 20px;
            display: flex;
            align-items: center;
            padding-left: 0;
            max-height: 150px;
            @include view-at(sp) {
                padding: 10px;
            }

            &-img {
                width: 35%;
                @include view-at(sp) {
                    width: 30%;
                    padding-right: 10px;
                }
            }
            &-text {
                width: 65%;
                line-height: 1.8em;
                font-size: 14px;
                letter-spacing: 0.15em;
                @include view-at(sp) {
                    width: 70%;
                    font-size: 12px;
                }
                span {
                    font-size: 13px;
                    letter-spacing: 0.15em;
                    @include view-at(sp) {
                        font-size: 10px;
                    }
                }
            }
        }
        &-text {
            color: $color-main;
            font-size: 14px;
            margin-bottom: 40px;
            letter-spacing: 0.1em;
            @include view-at(sp) {
                font-size: 10px;
                margin-bottom: 20px;
            }
        }

        a {
            background: $color-gray-dark;
            color: white;
            @include view-at(sp) {
                font-size: 13px;
            }
            &::after {
                color: white;
                @include view-at(sp) {
                    font-size: 8px;
                }
            }
        }
    }

    &-record {
        @include view-at(sp) {
            padding: 0 20px;
        }
        

        &-karte {
            text-align: center;
            &-name {
                color:$color-main;
                font-size: 27px;
                letter-spacing: 0.2em;
                margin-bottom: 30px;
                text-align: center;
            }
            &-massege {
                display: flex;
                flex-wrap: wrap;
                max-width: 600px;
                margin: 0 auto 30px;
                &-img {
                    width: 20%;
                    @include view-at(sp) {
                        width: 25%;
                        margin-right: 5%;
                    }
                }
                &-text {
                    width: 80%;
                    font-size: 18px;
                    line-height: 1.5em;
                    letter-spacing: 0.1em;
                    text-align: left;
                    @include view-at(sp) {
                        width: 70%;
                        font-size: 12px;
                        line-height: 1.8em;
                    }
                }
            }
        }
    }

    &-item {
        h2 {
            font-size: 27px;
            letter-spacing: 0.2em;
            margin-bottom: 40px;
            text-align: center;
            @include view-at(sp) {
                margin-bottom: 30px;
            }
    
            &.bule {
                color: $color-main;
                @include view-at(sp) {
                    font-size: 18px;
                }
            }
        }
        &-wrap {
            @include view-at(sp) {
                overflow-x: scroll;
            }
        }
        ul {
            @include view-at(sp) {
                width: 800px;
                margin-bottom: 10px;
            }
            li {
                @include view-at(sp) {
                    width: 25%;
                }
            }
        }
    }

    &-tips {
        text-align: center;
        margin: 140px 0;
        @include view-at(sp) {
            margin: 80px 0;
        }
        
        &-wrap {
            margin-bottom: 20px;
            @include view-at(sp) {
                overflow-x: scroll;
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: -20px;
            margin-bottom: 0;
            @include view-at(sp) {
                width: 600px;
                margin: -5px;
                margin-bottom: 20px;
            }
            li {
                padding: 20px;
                width: calc(100% /3);
                @include view-at(sp) {
                    padding: 5px;
                }
            }
        }
        &-date {
            text-align: center;
            margin-bottom: 10px;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            font-size: 14px;
            @include view-at(sp) {
                font-size: 12px;
                margin-bottom: 5px;
                text-align: left;
            }
        }
        &-text {
            text-align: center;
            line-height: 1.8em;
            font-size: 14px;
            @include view-at(sp) {
                font-size: 12px;
                text-align: left;
            }
        }
    }

    &-line {
        background: $color-gray-snow;
        @include view-at(sp) {
            padding: 40px 20px;
        }

        &-top {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            max-width: 530px;
            margin: 0 auto 40px;
            @include view-at(sp) {
                margin-bottom: 30px;
            }

            &-img {
                width: 15%;
                padding-right: 20px;
                @include view-at(sp) {
                    width: 16%;
                    padding-right: 4%;
                }
            }
            &-text {
                width: 85%;
                font-size: 27px;
                line-height: 1.5em;
                letter-spacing: 0.1em;
                text-align: left;
                @include view-at(sp) {
                    font-size: 14px;
                    width: 80%;
                }
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: -20px;
            margin-bottom: 10px;

            @include view-at(sp) {
                margin: -5px;
                margin-bottom: 20px;
            }
            li {
                width: calc(100% /3);
                padding: 20px;
                @include view-at(sp) {
                    width: calc(100% /1);
                    padding: 5px;
                }
            }
        }
        &-block {
            background: white;
            padding: 20px 30px;
            padding-left: 40px;
            justify-content: center;
            display: flex;
            align-items: center;
            
            @include view-at(sp) {
                padding: 15px;
                padding-left: 0;
            }

            &-num {
                font-size: 56px;
                color: #00B500;
                width: 20%;
                text-align: left;
                font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                @include view-at(sp) {
                    width: 20%;
                    text-align: center;
                    font-size: 30px;
                }
            }
            p {
                width: 80%;
                font-size: 14px;
                line-height: 1.8em;
                @include view-at(sp) {
                    text-align: left;
                    width: 80%;
                    font-size: 12px;
                }
            }
        }
        a {
            background: #00B500;
            color: white;
            border: none;
            &::after {
                color: white;
            }
        }
    }
    &-contact {
        p {
            letter-spacing: 0.1em;
            @include view-at(sp) {
                font-size: 14px;
            }
        }
    }

    //会員情報ページ
    &-member {
        margin: 80px 0 !important;
        @include view-at(sp) {
            margin: 40px 0 !important;
        }

        h2 {
            @include view-at(sp) {
                font-size: 20px;
            }
        }

        &-btn {
            margin: 80px 0 !important;
            @include view-at(sp) {
                margin: 40px 0 !important;
                padding: 0 20px;
            }
            ul {
                li {
                    a {
                        display: block;
                        padding: 15px;
                    }
                }
            }
        }
        &-information {
            font-family: 'Noto Sans JP';
            @include view-at(sp) {
                margin: 40px 0 !important;
            }
            &-wrap {
                border: 1px solid $color-gray;
                padding: 80px;
                @include view-at(sp) {
                    border: none;
                    padding: 0 20px;
                }
                label, input {
                    letter-spacing: 0.2em;
                }
            }
        }
        &-regular {
            font-family: 'Noto Sans JP';
            @include view-at(sp) {
                margin: 40px 0 !important;
            }
            &-wrap {
                border: 1px solid $color-gray;
                padding: 80px;
                @include view-at(sp) {
                    border: none;
                    padding: 0 20px;
                }

                p {
                    line-height: 1.5em;
                    @include view-at(sp) {
                    }
                }
            }
            &-block {
                max-width: 800px;
                margin: 0 auto 80px;
                @include view-at(sp) {
                    margin-bottom: 40px;
                }

                .box{
                    padding: 20px 40px;
                    display: inline-block;
                    border: 1px solid $color-gray-dark;
                    text-align: left;
                    font-size: 14px;
                    line-height: 1.8em;
                    @include view-at(sp) {
                        display: block;
                        font-size: 12px;
                        padding: 10px 20px;
                    }
                }

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                &-detail {
                    width: 100%;
                    tr {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        margin-bottom: 20px;
                        align-items: center;
                        font-size: 18px;
                        @include view-at(sp) {
                            font-size: 12px;
                        }
                        
                        th {
                            width: 50%;
                            background: $color-gray-snow;
                            padding: 15px 30px; 
                            letter-spacing: 0.2em;
                            @include view-at(sp) {
                                width: 35%;
                                padding: 10px 15px; 
                            }
                        }
                        td {
                            padding: 20px 40px;
                            letter-spacing: 0.2em;
                            @include view-at(sp) {
                                padding: 10px 15px;
                                width: 65%;
                                letter-spacing: 0.1em;
                            }
                        }
                    }

                    &-wrap {
                        border-top: 2px solid $color-gray-dark;
                        border-bottom: 2px solid $color-gray-dark;
                        margin-bottom: 40px;
                    }
                    &-btn {
                        padding: 20px;
                        text-align: center;
                        font-size: 15px;
                        letter-spacing: 0.1em;
                        position: relative;

                        &::after {
                            content: '';
                            width: 12px;
                            height: 12px;
                            border: 0px;
                            border-bottom: solid 2px $color-gray-dark;
                            border-right: solid 2px $color-gray-dark;
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                            position: absolute;
                            top: 35%;
                            right: 20px;
                        }

                        &.on {
                            &::after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                border: 0px;
                                border-bottom: solid 2px $color-gray-dark;
                                border-right: solid 2px $color-gray-dark;
                                -webkit-transform: rotate(225deg);
                                transform: rotate(225deg);
                                position: absolute;
                                top: 45%;
                                right: 20px;
                            }
                        }
                    }
                    &-item {
                        display: none;
                        margin-bottom: 40px;
                        &.active {
                            display: block;
                        }
                    }
                }

                ul {
                    li {
                        border-bottom: 1px solid $color-gray;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        &:nth-last-child(1) {
                            border: none;
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }

                        > .img {
                            width: 20%;
                            @include view-at(sp) {
                                width: 25%;
                            }
                        }

                        > .text {
                            width: 80%;
                            font-size: 18px;
                            text-align: left;
                            padding-left: 40px;
                            line-height: 1.8em;
                            letter-spacing: 0.2em;
                            @include view-at(sp) {
                                width: 75%;
                                padding-left: 20px;
                                font-size: 12px;
                                font-size: 12px;
                            }
                        }
                    }
                }

                &-ttl {
                    font-size: 18px;
                    text-align: center;
                    color: $color-main;
                    font-weight: bolder;
                    margin-bottom: 30px;
                    letter-spacing: 0.1em;
                    @include view-at(sp) {
                        font-size: 12px;
                        margin-bottom: 15px;
                    }
                }

                a {
                    padding: 15px 40px 15px 20px;
                    color: $color-main;
                    border-color: $color-main;

                    &::after {
                        color: $color-main;
                    }
                    @include view-at(sp) {
                        font-size: 12px;
                        line-height: 1.3em;
                        padding: 10px 30px 10px 15px;
                        letter-spacing: 0.1em;
                    }  
                }
                &-table1 {
                    &-payment {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 20px;
                        &-select {
                            width: 40%;
                            margin-right: 10px;
                            @include view-at(sp) {
                                width: 60%;
                            }
                            &-btn {
                                background: $color-gray-dark;
                                border: none;
                                padding: 10px 20px;
                                color: white;
                            }
                        }
                    }
                }

            }

            h3 {
                font-size: 27px;
                color: $color-main;
                @include center-line();
                margin-bottom: 5px;
                letter-spacing: 0.2em;
                margin-bottom: 80px;
                font-family: 'Tenor Sans', 'Noto Serif JP', serif;
    
                &::before,
                &::after {
                    background-color: $color-main;
                }

                @include view-at(sp) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }

            h4 {
                background: $color-gray-snow;
                padding: 15px 30px; 
                letter-spacing: 0.2em;
                margin-bottom: 20px;
                font-size: 18px;

                @include view-at(sp) {
                    font-size: 12px;
                    padding: 10px 20px; 
                    margin-bottom: 10px;
                }
            }
        }
    }

    &-history {
        &-form {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px;
            @include view-at(sp) {
                margin-bottom: 40px;
            }

            &-select {
                width: 150px !important;
                margin-right: 10px;
                padding: 0 20px;
                letter-spacing: 0.1em;
            }
        }

        &-head {
            margin-bottom: 80px;

            @include view-at(sp) {
                margin-bottom: 40px;
            }
            table {
                margin: 0 auto;
            }
            
            tr {
                display: flex;
                flex-wrap: wrap;
                border-top: 1px solid $color-gray-dark;
                border-bottom: 1px solid $color-gray-dark;
                th {
                    font-size: 12px;
                    padding: 10px;
                    width: 60%;
                    line-height: 1.5em;
                }
                td {
                    width: 40%;
                    background: $color-gray-dark;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 37px;
                    font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                }
            }
        }

        &-list {
            margin-bottom: 40px;
            @include view-at(sp) {
                margin-bottom: 30px;
            }
            li {
                display: flex;
                align-items: center;
            }
            &-img {
                width: 40%;
            }
            &-text {
                padding-left: 20px;
                width: 45%;
                line-height: 1.8em;
                font-size: 24px;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    font-size: 16px;
                }

                small {
                    font-size: 18px;
                    line-height: 1.5em;
                    @include view-at(sp) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .select-wrap{
        width: 100%;
        position: relative;
        display: inline-block;
      }
      .select-wrap::after {
        content: '▼';
        width: 6px;
        height: 6px;
        border: 0px;
        position: absolute;
        top: 50%;
        right: 40px;
        margin-top: -4px;
        font-size: 8px;
        @include view-at(sp) {
            right: 25px;
        }
      }
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 40px;
        background: transparent;
        position: relative;
        z-index: 1;
        padding: 0 40px 0 10px;
        border: 1px solid #b4b3b3;
        border-radius: 2px;
      }
      select::-ms-expand {
          display: none;
      }
    
      .block-member-info--birth {
        .select-wrap::after {
            right: 60px;
            @include view-at(sp) {
                right: 40px;
            }
          }
      }
}

progress[value] {
    appearance: none;
    background-color: $color-gray-light;
    border: 1px solid $color-gray-light;
    border-radius: 30px;
    color: $color-main; /* IE */
    height: 25px;
    width: 100%;
    @include view-at(sp) {
        height: 20px;
    }
  }
  /* chrome */
progress[value]::-webkit-progress-bar {
    background-color: $color-gray-light;
    border-radius: 30px;
  }
progress[value]::-webkit-progress-value {
    background-color: $color-main;
    border-radius: 30px;
  }
  /* firefox */
progress[value]::-moz-progress-bar {
    background-color: $color-main;
  }

//動的箇所CSS
.block-member-info--form {
    max-width: 800px;
    margin: 0 auto;
}
.fieldset .constraint {
    width: auto;
    padding: 0;
}
.required:after {
    display: inline;
    padding: 0;
    font-size: 11px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    content: "※";
    background: none;
    color: $color-text;
}
.fieldset .form-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 70px;
    @include view-at(sp) {
        margin-bottom: 40px;
    }
}
.fieldset .form-label {
    text-align: left;
    display: block;
    width: auto;
    margin-bottom: 20px;
    padding: 0;
    @include view-at(sp) {
        margin-bottom: 10px;
    }
    label {
        letter-spacing: 0.2em;
    }
}
.fieldset .form-control {
    width: 100%;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    letter-spacing: 0.2em;

    > span {
        width: 50%;
        padding-right: 20px;
        letter-spacing: 0.2em;
        @include view-at(sp) {
            padding-right: 10px;
        }
        &:nth-child(2) {
            padding-left: 20px;
            padding-right: 0;
            @include view-at(sp) {
                padding-left: 10px;
            }
        }
        input,select {
            width: 100%;
            padding: 7px 20px;
        }
    }
    
}
.block-member-info--update-mail {
    display: block;
    width: 50%;
    @include view-at(sp) {
        width: 100%;
    }

    a {
        display: block;
        text-align: center;
        color: #294C74;
        border-color: #294C74;
        @include view-at(sp) {
            font-size: 12px;
            padding: 10px;
        }

        &::after {
            color: #294C74; 
        }
    }
}
.block-member-info--sex {
    .form-control {
        display: block;
        span.js-efo {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            > span {
                width: calc(100%/ 3);
            }
            input {
                width: auto;
                margin-right: 20px;
                @include view-at(sp) {
                    margin-right: 5px;
                }
            }
        }
    }
}
.block-member-info--birth {
    span.checktype_date {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-right: 0;
        margin: 0 -10px;
        @include view-at(sp) {
            margin: 0 -5px;
        }
        

        > span {
            width: calc(100%/ 3);
            padding: 0 10px;
            display: flex;
            @include view-at(sp) {
                padding: 0 5px;
            }
            
            &:nth-child(2),
            &:nth-last-child(1) {
                padding: 0 10px;
                @include view-at(sp) {
                    padding: 0 5px;
                }
            }

            select {
                margin-right: 10px;
                @include view-at(sp) {
                    margin-right: 2px;
                }
            }
        }
    }
}
.block-member-info--custappend1 {
    .form-control {
        > span {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: -10px -20px;
            @include view-at(sp) {
                margin: -10px;
            }

            > span {
                width: calc(100%/ 3);
                padding: 10px 20px;
                @include view-at(sp) {
                    width: calc(100%/ 2);
                    letter-spacing: 0;
                    padding: 10px;
                }
                input {
                    width: auto;
                }
                label {
                    @include view-at(sp) {
                        margin: 0;
                        letter-spacing: 0;
                    }
                }
            }
            
        }
    }
}
.block-member-info--password {
    .form-control {
        > span {
            display: block;

            a {
                display: block;
                text-align: center;
                color: #294C74;
                border-color: #294C74;
                @include view-at(sp) {
                    font-size: 12px;
                    padding: 10px;
                }

                &::after {
                    color: #294C74; 
                }
            }
        }
    }
}
.block-member-info--items-mailnews {
    .form-control {
        display: block;
        > span {
            width: 100%;
            display: flex;
            padding: 0 !important;
            margin-bottom: 20px;
            input {
                width: auto;
                display: block;
            }
            p {
                width: 100%;
                a {
                    display: block;
                    text-align: center;
                    color: #294C74;
                    border-color: #294C74;
                    width: 100%;
                    @include view-at(sp) {
                        font-size: 12px;
                        padding: 10px;
                    }
            
                    &::after {
                        color: #294C74; 
                    }
                }
            }
        }
    }
}
.action-buttons {
    .action {
        display: block;
        cursor: pointer;
        transition: all 0.5s ease 0s;
        margin: 0 auto;
        &:hover {
            opacity: 0.6;
        }
        &.black {
            color: white;
            background: $color-gray-dark;
            border: none;
            &::after {
                content: "▶";
                color: white;
            }
            input {
                color: white;
            }
        }

        &.back {
            &::after {
                content: "◀";
                right: auto;
                left: 20px;
            }
        }
        a {
            display: inline-block;
        }
        input {
            display: block;
            width: 100%;
            text-align: center;
            background: none;
            border: none;
            cursor: pointer;
        }
    }
}

.block-member-info--tel,
.block-member-info--addr,
.block-member-info--addr2,
.block-member-info--addr3,
.block-member-info--password {
    .form-control {
        span {
            @include view-at(sp) {
                width: 100%;
                padding: 0;
            }
        }
    }
}

