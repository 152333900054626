.p-header {
  background-color: $color-white;
  padding: 10px;
  width: 100%;
  z-index: 9990;
  border-bottom: 1px solid #E6E6E6;
  @include view-at(sp) {
  }
  @include view-at(pc) {
    padding: 20px 20px 0;
    position: relative;
  }

  &-inner {
    position: relative;
  }

  &-logo {
    text-align: center;
    &.none {
      opacity:0;
            animation-name:sample01;
            animation-duration:1s;
            animation-fill-mode: forwards;
    }
    a {
      text-align: center;

      img {
        height: 80px;
        @include view-at(sp) {
          height: 60px;
        }
      }
    }
    &.after {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 8000;
      padding: 20px 30px;
      opacity: 0;
      @include view-at(sp) {
        display: none;
      }
      a {
        img {
          height: 100%;
        }
      }
      &.is-show {
        opacity:0;
            animation-name:sample01;
            animation-duration:1s;
            animation-fill-mode: forwards;
      }
    }
  }

  &-nav {

    @include view-at(sp) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      z-index: 1000;
      color: white;
      border-top: 1px solid #666464;
    }


    > ul {
        display: flex;
        flex-wrap: wrap;
        background: $color-white;

        @include view-at(pc) {
          position: absolute;
          right: 0;
          top: 10px;
          z-index: 9999;

          &.is-show {
            opacity:0;
            animation-name:sample01;
            animation-duration:1s;
            animation-fill-mode: forwards;
            position: fixed;
            top: 30px;
            right: 10px;
            background: none;
          }
        }

        li {
          width: calc(100%/5);
          position: relative;
          text-align: center;
          padding: 12px 10px;
          font-family: 'Noto Sans JP', sans-serif;
          letter-spacing: 0.05em;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;

          @include view-at(pc) {
            padding: 0 18px;
            height: auto;
            display: block;
          }

          a {
            display: block;
            width: 25px;

            @include view-at(pc) {
              width: 16px;
            }
          }

          &.purchase {
            display: none;
            &-p2 {
              display: none;
            }
          }
        }
    }

    &.after {
      > ul {
        li {
          width: calc(100%/4);
        }
      }
    }
  }
}


@keyframes sample01 {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  } 
}
