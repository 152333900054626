/* --------------------------
テーブル
-------------------------- */

.c-table {
  width: 100%;
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);

  thead {
    tr {
      @include view-at(sp) {
        border-bottom: 0;
      }
    }
    th {
      padding: 25px;
      line-height: 1.5;
      font-weight: $bold;
      width: 30%;
      background-color: $color-gray-snow;
      vertical-align: top;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
    td {
      font-weight: $bold;
      text-align: center;
      padding: 20px 30px;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        display: block;
        width: 100%;
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid $color-gray-light;
      border-left: 1px solid $color-gray-light;
      border-right: 1px solid $color-gray-light;
      @include view-at(sp) {
        display: flex;
      }
    }
    th {
      padding: 25px;
      line-height: 1.5;
      font-weight: $bold;
      width: 30%;
      background-color: $color-gray-snow;
      vertical-align: top;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        font-size: 12px;
        padding: 10px;
        width: 45%;
        line-height: 1;
        vertical-align: auto;
        display: flex;
        align-items: center;
      }
    }
    td {
      padding: 20px 30px;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        width: 55%;
        padding: 10px;
        font-size: 12px;
        text-align: right;

      }

      > p {
        text-align: left;
      }
    }
  }

  &--narrow {
    border-color: $color-gray-light;
    tbody {
      tr {
        border-color: $color-gray-light;
      }
      th {
        padding: 8px 10px;
        line-height: 1.5;
        font-weight: normal;
        width: 120px;
        font-weight: $bold;
        text-align: right;

        @include view-at(sp) {
          padding: 8px;
          width: 80px;
        }
      }
      td {
        padding: 8px 10px 8px 20px;
        line-height: 1.5;

        @include view-at(sp) {
          padding: 8px 10px 8px 15px;
        }
      }
    }
  }

  &--point {
    display: block;

    tbody{
      display: block;

      tr {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-gray-light;
        @include view-at(sp) {
          margin-bottom: 10px;
          padding-bottom: 10px;
        }

        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }
      th {
        width: 40%;
        display: flex;
        align-items: center;
        @include view-at(sp) {
          display: block;
        }

        .label {
          padding: 10px;
          line-height: 1.5;
          font-weight: $bold;
          background-color: $color-gray-snow;
          vertical-align: top;
          width: 20%;
          font-size: 18px;
          text-align: center;
          width: 40%;
    
          @include view-at(sp) {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 12px;
            padding: 5px 10px;
          }
        }
        .point {
          font-size: 38px;
          color: $color-main;
          font-family: 'Tenor Sans', 'Noto Serif JP', serif;
          padding-left: 20px;
          @include view-at(sp) {
            padding-left: 0;
            padding-top: 5px;
            font-size: 16px;
            text-align: center;
          }
        }
      }
      td {
        font-weight: $bold;
        text-align: center;
        padding: 0 20px;
        display: flex;
        align-items: center;
  
        @include view-at(sp) {
          display: block;
          width: 100%;
        }

        > p {
          line-height: 1.5em;
        }
      }
    }
    
  }
}
