/* --------------------------
タブ
-------------------------- */

.c-tab {
  &-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    max-width: 470px;
    margin: 0 auto;

    &-item {
      padding: 2px;
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;

      @include view-at(pc) {
        padding: 5px;
      }

      a {
        display: block;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        font-weight: $bold;
        font-size: 15px;
        letter-spacing: 0.4em;
        position: relative;
        cursor: pointer;
        transition: all 0.5s ease 0s;

        @include view-at(pc) {
          font-size: 16px;
        }

        &::after {
          content: '';
          width: 7px;
          height: 7px;
          border: 0px;
          border-bottom: solid 1px $color-text;
          border-right: solid 1px $color-text;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          bottom: -5px;
          left: 46%;
          margin-top: -4px;
          transition: all 0.5s ease 0s;
        }

        &[aria-selected="true"],
        &:hover {
          color: $color-main;
          text-decoration: underline;

          &::after {
            transform: rotate(225deg);
            border-color: $color-main;
          }
        }
      }
    }
  }

  &-contents {
    padding: 20px 0 40px;
    
    &-item {
      display: none;
      background-color: $color-white;
      border-top: 0;

      &[aria-hidden="false"] {
        display: block;
      }
    }
  }
}
