/* --------------------------
背景色
-------------------------- */
.u-bg-gray {
  background-color: $color-gray;
}
.u-bg-snow {
  background-color: $color-gray-snow;
}

.u-bg-light {
  background-color: $color-gray-snow;
}

.u-bg-white {
  background-color: $color-white;
}

.u-bg-green {
  background-color: $color-green-light;
}

.u-bg-blue {
  background-color: $color-blue;
}

.u-bg-gold {
  background-color: $color-gold;
}

.u-bg-blue-dark {
  background-color: $color-blue-dark;
}

.u-bg-red {
  background-color: $color-red-light;
}
