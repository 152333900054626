.p-gnav {
  background: white;
  margin-top: 15px;

  &-inner {
    position: absolute;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    top: 55px;
    transition: all 0.5s ease 0s;
    z-index: 5000;

    > ol {
      max-width: 1000px;
      margin: 0 auto 0;
      padding: 20px;
      display: flex;
      justify-content: center;
      background: rgba($color: white, $alpha: 0.95);

      > li {
        padding: 0 20px;
        font-size: 12px;
        font-family: 'Noto Serif JP', serif;

        div {
          font-family: 'Noto Sans JP';
          &:nth-child(1) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  > ul {
    display: flex;
    justify-content: center;
    position: relative;
    @include view-at(sp) {
      display: block;
    }
    
    > li {
      font-family: 'Tenor Sans', 'Noto Serif JP', serif;
      font-size: 14px;
      padding: 20px 30px;
      cursor: pointer;
        
      &:hover {
        .p-gnav-inner {
          height: auto;
          opacity: 1;
        }
      }
    }
  }

  &-products {
    justify-content: center;

    > li {
      border-right: 1px solid #E6E6E6;
      
      a {
        font-family: 'Noto Sans JP';
      }

      &:nth-child(1) {
        width: 25%;

        li {
          padding-bottom: 10px;
        }
      }
      &:nth-child(2) {
        width: 30%;

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            padding-bottom: 10px;
            width: 50%;
          }
        }
      }
      &:nth-child(3) {
        width: 20%;
        li {
          padding-bottom: 10px;
        }
      }
      &:nth-last-child(1) {
        border: none;
        width: 20%;
        li {
          padding-bottom: 10px;
        }
      }

      > div {
        font-size: 15px;
        color: #294C74;
        margin-bottom: 15px !important;
        font-family: 'Noto Serif JP', serif !important;
      }
    }
  }
  
}

#global-nav {
  background: #fff;
  width: 100%;

  &.m_fixed {
    left: 0;
    position: fixed;
    top: 0;
    z-index: 3000;
    margin-top: 0;
    padding: 10px;
    opacity:0;
    animation-name:sample01;
    animation-duration:1s;
    animation-fill-mode: forwards;
    @include view-at(sp) {
      display: none;
    }
  }
}