.cart {
    .input-reset {
        background: none;
        border: none;
    }
    &-ttl {
        font-size: 27px !important;
        color: #294C74 !important;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 5px;
        letter-spacing: 0.2em;
        margin-bottom: 30px !important;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        @include view-at(sp) {
            font-size: 16px !important;
            margin-bottom: 20px !important;
        }

        &::before, 
        &::after {
            content: "";
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            height: 1px;
            background: $color-main;
            display: block;
        }

        &::before {
            margin-right: 20px;
        }
        &::after {
            margin-left: 20px;
        }

        &2 {
            font-size: 27px;
            color: #294C74;
            margin-bottom: 5px;
            letter-spacing: 0.2em;
            margin-bottom: 30px;
            text-align: center;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            @include view-at(sp) {
                font-size: 16px !important;
                margin-bottom: 20px;
            }
        }
        &3 {
            background: $color-gray-snow;
            padding: 15px 30px !important;
            font-size: 18px !important;
            font-family: 'Noto Sans JP';
            font-weight: 300 !important;
            line-height: 1 !important;
            letter-spacing: 0.1em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            @include view-at(sp) {
                font-size: 12px !important;
                padding: 10px 20px !important;
                padding-right: 10px !important;
            }

            span {
                a {
                    margin-bottom: 0;
                    padding: 5px 10px;
                    font-size: 13px;
                    width: 200px;
                    font-family: 'Noto Sans JP';
                    background: none;
                    border: 1px solid #294C74;
                    letter-spacing: 0.2em;
                    line-height: 1;
                    color: #294C74;
                    display: block;
                    position: relative;
                    @include view-at(sp) {
                        width: auto;
                        padding: 5px 30px;
                        font-size: 12px;
                    }

                    &::after {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        content: '▶';
                        display: inline-block;
                        color: #294C74;
                        font-size: 8px;
                    }
                }
            }
        }
        &4 {
            font-size: 18px !important;
            font-family: 'Noto Sans JP';
            font-weight: 300 !important;
            line-height: 1 !important;
            letter-spacing: 0.1em;
            margin-bottom: 10px;
            background: none !important;
            @include view-at(sp) {
                font-size: 14px !important;
                margin-bottom: 10px;
            }
        }
    }
    &-wrap {
        border: 1px solid $color-gray-dark;
        padding: 80px;
        @include view-at(sp) {
            padding: 0;
            border: none;
        }
        &-inner {
            max-width: 900px;
            margin: 0 auto;
        }
    }
    &-item {
        border: none;
        width: 100%;
        float: none;
        
        &-list {
            margin-bottom: 30px;
            > li {
                margin: 0;
                margin-bottom: 20px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                border-bottom: 1px solid $color-gray-light;
                padding-bottom: 20px;
                position: relative;
                @include view-at(sp) {
                    padding-bottom: 10px;
                }
                &:nth-last-child(1) {
                    border-bottom: 1px solid $color-gray-light;
                }
            }
            &-img {
                width: 20%;
                margin-right: 5%;
                @include view-at(sp) {
                    width: 30%;
                    margin-right: 5%;
                    height: auto;
                }
                figure {
                    float: none;
                    width: 100%;
                    height: 100%;
                }
            }
            &-text {
                width: 50%;
                float: none;
                font-family: 'Noto Sans JP';
                @include view-at(sp) {
                    width: 65%;
                }
                
                &-name {
                    font-size: 18px !important;
                    line-height: 2em !important;
                    font-weight: 300 !important;
                    color: $color-text;
                    letter-spacing: 0.1em;
                    @include view-at(sp) {
                        font-size: 12px !important;
                    }
                    a {
                        letter-spacing: 0.1em;
                    }
                    
                }
                &-comment {
                    margin-bottom: 0;
                    p {
                        letter-spacing: 0.1em;
                        font-size: 18px;
                        line-height: 2em;
                        font-weight: 300;
                        color: $color-text;
                        margin: 0;
                        @include view-at(sp) {
                            font-size: 12px;
                        }
                        span {
                            letter-spacing: 0.1em;
                        }
                    }
                }
                &-price {
                    font-size: 18px;
                    line-height: 2em;
                    font-weight: 300 !important;
                    color: $color-text;
                    letter-spacing: 0.1em;
                    margin: 0;
                    width: auto;
                    float: none;
                    text-align: left;
                    @include view-at(sp) {
                        font-size: 12px;
                    }
                    span {
                        font-weight: 300 !important; 
                        letter-spacing: 0.1em;
                    }
                }
            }
            &-input {
                width: 25%;
                float: none;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                height: 100%;
                font-family: 'Noto Sans JP';
                @include view-at(sp) {
                    width: 100%;
                    margin-top: 10px;
                }
                &-qty {
                    width: 48%;
                    margin: 0;
                    height: 35px;
                    margin-right: 4%;
                    @include view-at(sp) {
                        width: 35%;
                        margin-right: 5%;
                        font-size: 12px;
                    }
                }
                &-sum {
                    width: 48%;
                    margin: 0;
                    height: 35px;
                    background: $color-gray-dark;
                    color: white;
                    @include view-at(sp) {
                        width: 35%;
                        margin-right: 5%;
                        font-size: 12px;
                    }
                }
                &-del {
                    width: auto;
                    margin-top: 10px;
                    @include view-at(sp) {
                        width: 20%;
                        font-size: 12px;
                    }
                }
            }
            &-quantity {
                position: absolute;
                bottom: 0;
                right: 0;
                font-family: 'Noto Sans JP';
                margin-bottom: 20px !important;
                line-height: 1em !important;
                @include view-at(sp) {
                    font-size: 12px !important;
                    margin-bottom: 10px !important;
                }
            }
        }
        &-sum {
            float: none;
            
            &-inner {
                width: 100%;
                margin: 0;
                text-align: center;
            }
            &-procedure {
                padding: 0;
                margin: 0;
                margin-bottom: 80px;
                border-color: white;
                font-family: 'Noto Sans JP';
                @include view-at(sp) {
                    margin-bottom: 60px;
                }
                &-inner {
                    background: none;
                    padding: 0;
                }
                &-subtotal {
                    padding: 0;
                    text-align: right;
                    font-size: 20px;
                    @include view-at(sp) {
                        font-size: 16px;
                    }
                    span {
                        font-size: 20px;
                        font-weight: 300;
                        color: $color-text;
                        letter-spacing: 0.1em;
                        @include view-at(sp) {
                            font-size: 16px;
                        }
                    }
                }
            }
            &-btn {
                padding: 0;
                background: none;
                border: none;
                font-size: 15px;
                margin-bottom: 0;
                font-weight: normal;
                display: block;
                margin-top: 0;
                font-family: 'Noto Sans JP';
                letter-spacing: 0.1em;
                &s {
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    display: block;
                    margin-top: 0;
                    > input {
                        text-decoration: none;
                        font-family: 'Noto Sans JP';
                        letter-spacing: 0.1em;
                    }
                }
                &2 {
                    padding: 0;
                    background: none;
                    border: none;
                    font-size: 15px;
                    margin-bottom: 0;
                    font-weight: normal;
                    color: $color-text;
                    display: block;
                    margin-top: 0;
                }
            }
        }
    }
    &-bar {
        margin-bottom: 80px;
        @include view-at(sp) {
            margin-bottom: 40px;
        }
        ul {
            position: relative;
            list-style: none;
            margin: 0 0 1em;
            padding: 0;
            text-align: center;
            width: 100%;
            overflow: hidden;
            *zoom: 1;

            @include view-at(sp) {
                border-width: 1px;
            }
            li.step {
                position: relative;
                float: left;
                display: inline-block;
                line-height: 40px;
                padding: 0 40px 0 20px;
                background-color: white;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 25%;
                border-top: 1px solid $color-gray-dark;
                border-bottom: 1px solid $color-gray-dark;
                font-family: 'Noto Sans JP';
                @include view-at(sp) {
                    padding: 0 20px 0 10px;
                    font-size: 10px;
                    line-height: 1.3;
                    height: 50px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            
                &::before, &::after {
                    position: absolute;
                    right: -15px;
                    display: block;
                    content: '';
                    background-color: white;
                    border-left: 1px solid $color-gray-dark;
                    border-top: 1px solid  $color-gray-dark;
                    border-bottom: 1px solid $color-gray-dark;
                    width: 20px;
                    height: 27px;
                    @include view-at(sp) {
                        width: 15px;
                        right: -7px;
                        border-width: 1px;
                        height: 25px;
                    }
                }
                &::after {
                    top: -1px;
                    border-top: 1px solid #717171;
                    -moz-transform: skew(30deg);
                    -ms-transform: skew(30deg);
                    -webkit-transform: skew(30deg);
                    transform: skew(30deg);
                }
                &::before {
                    bottom: -1px;
                    border-bottom: 1px solid #717171;
                    -moz-transform: skew(-30deg);
                    -ms-transform: skew(-30deg);
                    -webkit-transform: skew(-30deg);
                    transform: skew(-30deg);
                }
                
                &.current {
                    color: #FFF;
                    background-color:$color-gray-dark;

                    &-en {
                        &::before, &::after {
                            background-color:$color-gray-dark;
                        }
                    }
                }
                &:nth-child(1) {
                    border-left: 1px solid $color-gray-dark;
                }
                &:nth-last-child(1) {
                    &::before, &::after {
                        border-bottom: none;
                        border-top: none;
                    }
                }
            }
        }
    }
    &-check {
        &-icon {
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 9999;
            span {
                padding: 0px 8px;
                border: 1px solid $color-main;
                font-size: 12px;
                border-radius: 10px;
                color: $color-main;
                font-family: 'Noto Sans JP';
                line-height: 1;
                font-weight: 600;
            }
        }
        &-list {
            @include view-at(sp) {
                width: 650px;
                margin-bottom: 20px;
            }
            
            &-wrap {
                @include view-at(sp) {
                    overflow-x: scroll;
                }
            }
            >li {
                width: 25%;
            }
        }
    }
    &-constraint {
        background: none !important;
        margin-bottom: 20px;
        margin-left: 5px;
    }
    &-form {
        &-label {
            background: none !important;
            margin-bottom: 20px !important;
        }
    }
    &-add {
        font-family: 'Noto Sans JP';
        font-weight: 300 !important;
        > ul {
            border: none;
            margin: 0;
            padding: 0;
        }
        &-wrap {
            border: none;
            margin: 0;
            padding: 0;
            font-family: 'Noto Sans JP';
            width: 100%;
        }
        &-name {
            font-size: 18px !important;
            line-height: 2em;
            margin-bottom: 0;
            font-weight: 300 !important;
            letter-spacing: 0.1em;
            @include view-at(sp) {
                font-size: 14px !important;
            }
        }
        &-text {
            font-size: 18px !important;
            line-height: 2em;
            margin: 0 !important;
            letter-spacing: 0.1em;
            font-weight: 300 !important;
            @include view-at(sp) {
                font-size: 14px !important;
            }
        }
        &-list {
            padding: 0;
            margin-bottom: 40px;
            border: none;
            font-family: 'Noto Sans JP';
            @include view-at(sp) {
                margin-bottom: 20px;
            }
            &-ttl {
                display: flex;
                align-items: center;
                label {
                    margin-left: 10px;
                }
            }
            &-cycle {
                display: flex;
                align-items: center;
                @include view-at(sp) {
                    display: block;
                }
                dd {
                    margin-right: 30px;
                    display: flex;
                    align-items: center;
                    @include view-at(sp) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        &-wrapping {
            border: none;
            font-size: 18px;
            width: 100%;
            @include view-at(sp) {
                font-size: 14px !important;
            }
            tr {
                display: block;
            }
            th {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: none;
                background: $color-gray-snow;
                padding: 15px 30px !important;
                font-size: 18px !important;
                font-family: 'Noto Sans JP';
                font-weight: 300 !important;
                line-height: 1 !important;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    font-size: 12px !important;
                    padding: 10px 20px !important;
                    padding-right: 10px !important;
                }

                a {
                    @include view-at(sp) {
                        width: auto;
                        padding: 5px 30px;
                        font-size: 12px;
                    }
                }
            }
            td {
                border: none;
                line-height: 1;
                font-family: 'Noto Sans JP';
                letter-spacing: 0.1em;
                display: flex;
                align-items: center;
                > input {
                    margin-right: 20px;
                    
                    @include view-at(sp) {
                        margin-right: 10px;
                    }
                }
            }
            &-btn {
               margin-bottom: 0;
               padding: 5px 10px;
               font-size: 13px;
               width: 200px;
               font-family: 'Noto Sans JP';
               &::after {
                   right: 10px;
               }
            }
        }
        &-timing {
            background: none;
            border: none;
            padding: 0;

            &-radio {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                
            }
            &-select {
                display: flex;
                align-items: flex-end;
                select {
                    width: 200px;
                    padding: 5px 15px;
                    @include view-at(sp) {
                        width: auto;
                    }
                }
            }
        }
        &-cycle {
            width: 100%;
            border: none;
            &-wrap {
                width: 100%;
            }
            dl {
                display: flex;
                flex-wrap: wrap;
                border: 1px solid $color-gray-light !important;
                @include view-at(sp) {
                    font-size: 12px;
                }
                &:nth-child(1) {
                    border-bottom: none !important;
                }

                dt {
                    width: 50%;
                    background: $color-gray-snow;
                    text-align: left;
                    padding: 10px 20px;
                    border:none;
                    letter-spacing: 0.1em;
                    font-weight: 400;
                    @include view-at(sp) {
                        letter-spacing: 0em;
                        text-align: center;
                    }
                }
                dd{
                    width: 50%;
                    font-weight: 400;
                    text-align: right;
                    padding: 10px 20px;
                    letter-spacing: 0.1em;
                    @include view-at(sp) {
                        letter-spacing: 0em;
                        text-align: center;
                    }
                }
            }
            tr {
                display: flex;
                flex-wrap: wrap;
                border: 1px solid $color-gray-light !important;
                color: $color-text;
                border-bottom: none !important;
                @include view-at(sp) {
                    font-size: 12px;
                }
                &:nth-last-child(1) {
                    border-bottom: 1px solid $color-gray-light !important;
                }

                th {
                    width: 50%;
                    background: $color-gray-snow;
                    text-align: left;
                    padding: 10px 20px;
                    border:none;
                    letter-spacing: 0.1em;
                    font-weight: 300;
                    color: $color-text;
                    font-size: 18px;
                    @include view-at(sp) {
                        letter-spacing: 0em;
                        font-size: 12px;
                    }
                }
                td{
                    width: 50%;
                    font-weight: 300;
                    text-align: right;
                    padding: 10px 20px;
                    letter-spacing: 0.1em;
                    border:none;
                    font-size: 18px;
                    @include view-at(sp) {
                        letter-spacing: 0em;
                        font-size: 12px;
                    }
                }
            }

        }
        &-date {
            &-spec {
                font-family: 'Noto Sans JP';
                dt {
                    float: none !important;
                    margin-bottom: 10px;
                    font-size: 18px;
                    font-weight: 300;
                    @include view-at(sp) {
                        font-size: 12px;
                    }
                }
                dd {
                    float: none !important;
                    margin: 0 !important;
                    padding-bottom: 10px;
                    width: 100%;
                    @include view-at(sp) {
                        padding-bottom: 5px;
                    }

                    span {
                        font-size: 14px;
                        @include view-at(sp) {
                            font-size: 11px;
                            line-height: 1.2em;
                            margin-top: 0;
                        }
                    }
                    select {
                        width: 50%;
                        @include view-at(sp) {
                            font-size: 12px;
                            padding: 10px;
                        }
                    }
                }
            }
        }
        &-payment {
            font-family: 'Noto Sans JP';
            display: flex;
            flex-wrap: wrap;
            li {
                width: calc(100%/3);
                letter-spacing: 0.1em;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                font-size: 18px;
                @include view-at(sp) {
                    width: calc(100%/1);
                    margin-bottom: 10px;
                    font-size: 14px;
                }

                label {
                    margin-left: 10px;
                    letter-spacing: 0.1em;

                }
            } 
        }
        &-point {
            font-size: 18px;
            font-family: 'Noto Sans JP';
            margin-bottom: 20px;
            @include view-at(sp) {
                font-size: 14px;
                margin-bottom: 10px;
            }

            span {
                font-weight: 300;
            }
            &-use {
                margin-bottom: 20px;
                background: none;
                font-size: 18px;
                display: flex;
                align-items: center;
                @include view-at(sp) {
                    font-size: 14px;
                }
                &-input {
                    border-color:  $color-gray-snow !important;
                    background: $color-gray-snow !important;
                    text-align: center;
                }
                &-label {
                    @include view-at(sp) {
                        padding: 0 !important;
                    }
                }
            }
        }
        &-cp{
            line-height: 1.8em;
            @include view-at(sp) {
                font-size: 12px;
            }
            &-code {
                margin-top: 20px;
                display: flex;
                input {
                    height: 40px; 
                }
                &-input {
                    @include view-at(sp) {
                        width: 60%;
                        > input {
                            width: 100%;
                        }
                    }
                }
                &-on {
                    height: 40px;
                    margin-left: 20px;
                    background: gray;
                    color: white;
                    font-size: 15px;
                    padding: 0 30px;
                    @include view-at(sp) {
                        font-size: 12px;
                        margin-left: 10px;
                        padding: 0 20px;
                    }
                }
            }
            
        }
        &-other {
            border: none !important;
            display: flex;
            align-items: center;
            > label {
                margin-right: 40px;
                margin-bottom: 0;
                margin-left: 10px;
            }
        }
        &-box {
            border: none;
            tr {
                display: block;
                border: none;
                th {
                    border: none;
                    width: 100%;
                    background: #F0F0F0;
                    padding: 15px 30px;
                    font-size: 18px !important;
                    font-family: 'Noto Sans JP';
                    font-weight: 300 !important;
                    line-height: 1 !important;
                    letter-spacing: 0.1em;
                    color: $color-text;
                    margin-bottom: 10px;
                    @include view-at(sp) {
                        font-size: 14px !important;
                    }
                }
                td {
                    border: none;
                    font-size: 18px;
                    line-height: 22px;
                    padding: 0;
                    @include view-at(sp) {
                        font-size: 14px !important;
                    }
                }
            }
        }
        &-sum {
            position: static !important;
            border-top: 1px solid $color-gray-light;
            float: none;
            width: 100%;
            margin-top: 80px;
            @include view-at(sp) {
                margin-top: 40px;
            }
            &-inner {
                margin: 0;
                width: 100%;
                &-in {
                    border: none;
                    padding: 0;
                }
            }
            &-container {
                background: none;
                padding: 0;
                &-total {
                    text-align: right;
                    padding: 0;
                    font-size: 20px;
                    font-family: 'Noto Sans JP';
                    font-weight: 300 !important;
                    margin: 40px 0;
                    letter-spacing: 0.1em;
                    @include view-at(sp) {
                        font-size: 16px;
                        margin: 20px 0;
                    }
                    span {
                        font-size: 20px;
                        color:$color-text;
                        font-weight: 300 !important;
                        letter-spacing: 0.1em;
                        @include view-at(sp) {
                            font-size: 16px;
                        }
                    }
                    &-item {
                        margin: 0;
                        border: 1px solid $color-gray-light;
                        border-bottom: none;
                        font-family: 'Noto Sans JP';
                        &.end {
                            border-bottom:  1px solid $color-gray-light;
                            margin-bottom: 20px;
                        }
                        dt {
                            font-size: 18px;
                            width: 50%;
                            background: $color-gray-snow;
                            padding: 15px 30px;
                            margin: 0;
                            letter-spacing: 0.1em;
                            @include view-at(sp) {
                                padding: 10px 15px;
                                font-size: 12px;
                                width: 60%;
                            }
                        }
                        dd {
                            font-size: 18px;
                            width: 50%;
                            padding: 15px 30px;
                            letter-spacing: 0.1em;
                            @include view-at(sp) {
                                padding: 10px 15px;
                                font-size: 12px;
                                width: 40%;
                            }
                        }
                    }
                    &-point {
                        border: none;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        font-family: 'Noto Sans JP';
                        font-weight: 300 !important;
                        letter-spacing: 0.1em;
                        dt {
                            width: auto;
                            font-size: 20px;
                            letter-spacing: 0.1em;
                            @include view-at(sp) {
                                font-size: 16px;
                            }
                        }
                        dd {
                            width: auto;
                            font-size: 20px;
                            letter-spacing: 0.1em;
                            @include view-at(sp) {
                                font-size: 16px;
                            }
                            span {
                                font-size: 20px;
                                color: $color-text;
                                letter-spacing: 0.1em;
                                @include view-at(sp) {
                                    font-size: 16px;
                                }
                            }
                            &:nth-last-child(1) {
                                width: 100%;
                                text-align: right;
                                margin-top: 20px;
                                font-size: 14px;
                                letter-spacing: 0.1em;
                                color: $color-text;
                                @include view-at(sp) {
                                    font-size: 12px;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-card {
            margin-top: 20px;
           dl {
               display: flex;
               flex-wrap: wrap;
               margin-bottom: 20px;
               font-size: 18px;
               @include view-at(sp) {
                font-size: 12px;
                margin-bottom: 10px;
                }
               dt {
                background: $color-gray-snow;
                width: 40%;
                padding: 15px 30px;
                margin-top: 0;
                @include view-at(sp) {
                    padding: 10px 20px;
                }
               }
               dd {
                font-size: 18px;
                padding: 15px 30px;
                @include view-at(sp) {
                    font-size: 14px;
                    width: 60%;
                    padding: 10px 20px;
                }
               }
           }
           &-btn {
               label {
                   margin: 0;
               }
           }
        }
        &-orderid {
            border:none;
            tr {
                font-size: 27px !important;
                color: #294C74 !important;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;
                letter-spacing: 0.2em;
                margin-bottom: 30px !important;
                font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                @include view-at(sp) {
                    font-size: 16px !important;
                    margin-bottom: 20px !important;
                }
                &::before, 
                &::after {
                    content: "";
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                    height: 1px;
                    background: $color-main;
                    display: block;
                }

                &::before {
                    margin-right: 20px;
                }
                &::after {
                    margin-left: 20px;
                }
                th {
                    border:none;
                    background: none;
                    width: auto;
                }
                td {
                    border:none;
                    width: auto;
                    a {
                        color: #294C74 !important;
                    }   
                }
            }
        }
        &-message {
            font-size: 14px;
            line-height: 1.5em;
            @include view-at(sp) {
                font-size: 12px;
            }
        }
        &-novelty {
            padding: 20px 30px;
            border: 1px solid $color-gray-light;
            @include view-at(sp) {
                padding: 20px;
            }

            ul {
                display: flex;
                @include view-at(sp) {
                    display: block;
                }

                li {
                    padding-right: 20px;
                    font-size: 18px;
                    @include view-at(sp) {
                        padding: 0;
                        margin-bottom: 10px;
                        font-size: 12px;
                    }
                    div {
                        padding: 0;
                    }
                }
            }
        }
    }
    .list-modal-inner {
        display: none;
    }

    /* iOSでのデフォルトスタイルをリセット */
    input[type="submit"],
    input[type="button"] {
    border-radius: 0;
    -webkit-box-sizing: content-box;
    -webkit-appearance: button;
    appearance: button;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    }
    input[type="submit"]::-webkit-search-decoration,
    input[type="button"]::-webkit-search-decoration {
    display: none;
    }
    input[type="submit"]::focus,
    input[type="button"]::focus {
    outline-offset: -2px;
    }
    input {
        border-radius: 0;
    }
    input:focus {
        outline-color: transparent;
        outline: none;
    }

    // ラジオボタン
    input[type=checkbox],input[type=radio] {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        position: relative;
        right: 0;
        bottom: 0;
        left: 0;
        height: 20px;
        width: 20px;
        vertical-align: -0.8rem;
        transition:all .15s ease-out 0s;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        outline: none;
        border-radius: 10%;
    }
    /* Checkbox */
    input[type=checkbox]:before,input[type=checkbox]:after {
        position: absolute;
        content: "";
        background: #fff;
        transition: all .2s ease-in-out;
    }
    input[type=checkbox]:before {
        left: 2px;
        top: 6px;
        width: 0;
        height: 2px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
    }
    input[type=checkbox]:after {
        right: 9px;
        bottom: 3px;
        width: 2px;
        height: 0;
        transform: rotate(40deg);
        -webkit-transform: rotate(40deg);
        -moz-transform: rotate(40deg);
        -ms-transform: rotate(40deg);
        -o-transform: rotate(40deg);
        transition-delay: .2s;
    }
    input[type=checkbox]:checked:before {
        left: 1px;
        top: 10px;
        width: 6px;
        height: 2px;
    }
    input[type=checkbox]:checked:after {
        right: 5px;
        bottom: 1px;
        width: 2px;
        height: 14px;
    }
    input[type=checkbox]:indeterminate:before,input[type=checkbox]:indeterminate:after {
        width: 7px;
        height: 2px;
        transform: rotate(0);
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
    }
    input[type=checkbox]:indeterminate:before {
        left: 1px;
        top: 7px;
    }
    input[type=checkbox]:indeterminate:after {
        right: 1px;
        bottom: 7px;
    }
    /* Radio */
    input[type=radio] {
        border-radius: 50%;
        border: 2px solid #294C74;
    }
    input[type=radio]:checked:before {
        transform: scale(1);
    }
    input[type=radio]:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 3px;
        transform: scale(0);
        transition: all ease-out 250ms;
    }
    /* 色は青色のみ */
    input[type=checkbox].blue,
    input[type=radio].blue {
        border: 2px solid $color-main;
    }
    input[type=checkbox].blue:checked,
    input[type=checkbox].blue:indeterminate,
    input[type=radio].blue:checked:before {
        background: $color-main;
    }

    select::-ms-expand {
        display: none;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 7px 30px 7px 10px;
        font-size: 93%;
        line-height: 1.1em;
        background-image: url(../../img/usr/common/arrow.png);
        background-repeat: no-repeat;
        background-size: 8px 5px;
        background-position: right 10px center;
        background-color: white;
        border-radius: 0;

        &:focus {
            outline-color: transparent;
            outline: none;
          }
    }
}