.privacy {
    &-h1 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 60px;
        line-height: 1.8em;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-main;
        letter-spacing: 0.1em;
        @include view-at(sp) {
            margin-bottom: 30px;
        }
        &::before, &::after {
            content: "";
            height: 1px;
            background: $color-main;
            display: block;
            flex-grow: 1;
        }
        &::before {
            margin-right: 20px;
        }
        &::after {
            margin-left: 20px;
        }
    }
    p {
        line-height: 1.8em;
        overflow-wrap: anywhere;
        text-align: justify;
        text-justify: inter-ideograph;
        &.u-text-min {
            font-size: 14px;
        }
    }

    strong {
        font-weight: bolder;
    }
    &-cap {
        font-size: 11px;
        line-height: 1.5em;
    }

    &-table {
        width: 100%;
        display: block;
        tbody {
            width: 100%;
            display: block;
            tr {
                display: block;
                margin-bottom: 40px;
                th {
                    display: block;
                    font-size: 20px;
                    margin-bottom: 10px;
                    line-height: 1.5em;
                    @include view-at(sp) {
                        margin-bottom: 5px;
                    }
                }
                td {
                    font-size: 14px;
                    line-height: 1.8em;
                    text-align: justify;
                    text-justify: inter-ideograph;
                }
            }
        }
        
        &-list {
            > li {
                line-height: 1.5em;
                margin-bottom: 20px;
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }
            &2 {
                > li {
                    line-height: 1.5em;
                    margin-bottom: 10px;
                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                } 
            }
        }
        &-num {
            > li {
                list-style: decimal;
                line-height: 1.5em;
                margin-left: 20px;
                margin-bottom: 30px;
                &:nth-last-child(1) {
                    margin-bottom: 0;
                    margin-left: 0;
                }
                @include view-at(sp) {
                    margin-left: 0;
                }
            }
            &-min {
                > li {
                    list-style: decimal;
                    line-height: 1.5em;
                    margin-left: 20px;
                    margin-bottom: 5px;
                    letter-spacing: 0.1em;
                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                    @include view-at(sp) {
                        margin-left: 0;
                    }
                }
            }
        }
        &-disr {
            > li {
                list-style: disc;
                line-height: 1.5em;
                margin-left: 20px;
                margin-bottom: 5px;
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
                @include view-at(sp) {
                    margin-left: 0;
                }
            }
        }
        &-katakana {
            > li {
                list-style: katakana;
                margin-left: 20px;
                @include view-at(sp) {
                    margin-left: 0;
                }
            }
        }
        &-space {
            margin-left: 26px;
            @include view-at(sp) {
                margin-left: 0;
            }
        }
    }
}

.campaign {
    &-mv {
        background: url(../../img/usr/mypage/mv.png) no-repeat;
        background-size: cover;
        color: white;

        a {
            color: white;
        }
    }
    &-wrap {
        border: 1px solid $color-gray;
        padding: 80px;
        @include view-at(sp) {
            border: none;
            padding: 0;
            margin: 40px 0;
        }
    }

    &-tab {
        &-nav {
            max-width: 100%;
            margin-bottom: 40px;
            @include view-at(sp) {
                margin-bottom: 0;
            }
            li {
                width: calc(100%/3);
                a {
                    border: 1px solid;
                    padding: 15px 0;
                    display: block;
                    @include view-at(sp) {
                        letter-spacing: 0.1em;
                    }
                    
                    &:hover {
                        text-decoration: none;
                        background: $color-main;
                        color: white;
                        opacity: 1;
                    }
                    &::after {
                        content: none;
                    }
                    &[aria-selected="true"] {
                        text-decoration: none;
                        background: $color-main;
                        color: white;
                    }
                }

                &:nth-child(1) {
                    width: 100%;
                }
            }
        }
        &-contents {
            padding-bottom: 0;
            min-height: 300px;
        }
    }
    &-list {
        margin: -20px;
        @include view-at(sp) {
            margin: -5px;
        }
        &-item {
            margin-bottom: 20px;
            padding: 20px;
            @include view-at(sp) {
                padding: 5px;
            }

            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            table, tr ,tbody{
                display: block;
                width: 100%;
            }
            th,td {
                display: block;
                letter-spacing: 0.1em;
                line-height: 1.5em;
                text-align: center;
                @include view-at(sp) {
                    text-align: left;
                }
            }
            th {
                font-size: 18px;
                @include view-at(sp) {
                    font-size: 10px;
                }
                margin-bottom: 5px;
            }
            td {
                @include view-at(sp) {
                    font-size: 10px;
                }
            }
            &-img {
                margin-bottom: 15px;
                @include view-at(sp) {
                    margin-bottom: 10px;
                }
            }
            &.picup {
                width: 100%;
                @include view-at(sp) {
                    .campaign-list-item-img {
                        margin: 0 -20px 20px;
                    }
                    th,td {
                        text-align: center;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.withdrawal {
    .page-mv-text{
        .ttl {
            color: $color-main;
        }
    }

    &-wrap {
        border: 1px solid $color-gray;
        padding: 80px;
        @include view-at(sp) {
            border: none;
            padding: 0;
            margin: 40px 0;
        }
    }

    h3 {
        font-size: 27px;
        color: $color-main;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        letter-spacing: 0.2em;
        margin-bottom: 80px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        @include view-at(sp) {
            font-size: 16px;
            margin-bottom: 40px;
        }
        
        &::before, &::after {
            content: "";
            flex-grow: 1;
            height: 1px;
            background: $color-main;
            display: block;
        }
        &::before {
            margin-right: 20px;
        }
        &::after {
            margin-left: 20px;
        }
    }
    &-table {
        margin: 0 auto 40px;
        @include view-at(sp) {
            margin-bottom: 0;
        }
        tr {
            border: none !important;
            margin-bottom: 40px;
            display: block !important;
            &:nth-last-child(1) {
                margin-bottom: 0;
            }
            
            th {
                background: none;
                border: none;
                width: 100%;
                margin-bottom: 10px;
                padding: 0;
                display: block !important;
                font-size: 18px;
                @include view-at(sp) {
                    font-size: 14px;
                }
            }
            td {
                border: none;
                width: 100%;
                margin-bottom: 10px;
                padding: 0;
                display: block !important;

                input {
                    width: 100%;
                }
            }
        }
    }
    &-btns {
        margin: -10px;
        margin-bottom: 80px;
        
        @include view-at(sp) {
            display: block;
            margin-bottom: 40px;
            margin-top: 40px;
        }

        > li {
            text-align: center;
            padding: 10px;
        }
    }
    &-contact {
        background: $color-gray-snow;
        padding: 40px;
        text-align: center;
        margin-bottom: 80px;
        @include view-at(sp) {
            padding: 20px;
            margin-bottom: 40px;
            text-align: left;
        }

        a {
            font-size: 22px;
            letter-spacing: 0.1em;
            margin-bottom: 10px;
            display: block;

            img {
                width: 30px;
                margin-right: 10px;
                display: inline-block;
            }
        }
    }
}

.shop {
    &-wrap {
        border: 1px solid $color-gray;
        padding: 80px;
        @include view-at(sp) {
            border: none;
            padding: 0;
            margin: 40px 0;
        }
    }

    &-list {
        
        &-item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 80px;
            @include view-at(sp) {
                margin-bottom: 40px;
            }
            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            h3 {
                  font-size: 27px;
                  margin-bottom: 40px;
                  letter-spacing: 0.1em;
                  text-align: center;
                  @include view-at(sp) {
                    margin-bottom: 20px;
                    font-size: 22px;
                }
            }

            &-img {
                width: 100%;
                padding: 20px;
                @include view-at(sp) {
                    width: 100%;
                    padding: 10px;
                }
            }
            &-text {
                width: 100%;
                padding: 20px;
                @include view-at(sp) {
                    padding: 10px;
                }
                
                &-add {
                    position: relative;

                    > a {
                        margin-top: 5px;
                        font-size: 14px;
                        text-decoration: underline;
                        display: flex;
                        align-items: center;
                        line-height: 1;
                        position: absolute;
                        top: 0;
                        right: 0;
                        @include view-at(sp) {
                            font-size: 12px;
                        }
                        span {
                            display: block;
                            padding-right: 5px;
                            img {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.question {
    &-wrap {
        border: 1px solid $color-gray;
        padding: 80px;
        @include view-at(sp) {
            border: none;
            padding: 0;
            margin: 40px 0;
        }
    }

    &-nav {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
        margin-bottom: 80px;
        @include view-at(sp) {
            margin-bottom: 40px;
        }

        li {
            width: calc(100%/3);
            padding: 5px;
            @include view-at(sp) {
                width: calc(100%/2);
                &:nth-last-child(1) {
                    width: 100%;
                }
            }

            a {
                display: block;
                padding: 20px 0;
                border: 1px solid $color-gray;
                text-align: center;
                font-size: 15px;
                color: $color-gray;
                letter-spacing: 0;
                transition: all 0.5s ease 0s;
                font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                text-decoration: none;
                @include view-at(sp) {
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 1.5em;
                    height: 60px;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &:hover {
                    color: $color-white;
                    border: 1px solid $color-main;
                    background: $color-main;
                    opacity: 1;
                }
            }
        }
    }

    h3 {
        font-size: 27px;
        color: $color-main;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        letter-spacing: 0.2em;
        margin-bottom: 40px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        
        @include view-at(sp) {
            font-size: 16px;

        }
        
        &::before, &::after {
            content: "";
            flex-grow: 1;
            height: 1px;
            background: $color-main;
            display: block;
        }
        &::before {
            margin-right: 20px;
        }
        &::after {
            margin-left: 20px;
        }
    }

    &-list {
        &-item {
            margin-bottom: 30px;
            @include view-at(sp) {
                margin-bottom: 20px;
            }
            &:nth-last-child(1) {
                margin-bottom: 0;
            }
            &-q {
                background: $color-gray-snow;
                padding: 15px;
                font-size: 16px;
                position: relative;
                line-height: 1.5em;
                padding-right: 60px;
                cursor: pointer;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    padding-right: 45px;
                    font-size: 14px;
                }
                a {
                    text-decoration: none;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 15px;
                    width: 25px;
                    height: 25px;
                    background: url(../../img/usr/others/plus.png) no-repeat;
                    background-size: cover;
                    background-position: center;
                    @include view-at(sp) {
                        width: 20px;
                        height: 20px;
                        right: 15px;
                    }
                }

                &.on-click {
                    &::after {
                        background: url(../../img/usr/others/minus.png) no-repeat;
                        background-size: 100%;
                        background-position: center;
                    }
                }
            }
            &-a {
                padding: 15px;
                font-size: 14px;
                line-height: 1.8em;
                @include view-at(sp) {
                    padding: 15px 0;
                }
                b {
                    color: red;
                    > a {
                        color: red;
                    }
                }
            }
        }
    }
}

.guide {
    &-wrap {
        border: 1px solid $color-gray;
        padding: 80px;
        @include view-at(sp) {
            border: none;
            padding: 0;
            margin: 40px 0;
        }
    }
    &-list {
        &-item {
            margin-bottom: 40px;
            border-top: 1px solid $color-gray-light;
            border-bottom: 1px solid $color-gray-light;
            &:nth-last-child(1) {
                margin-bottom: 0;
            }
            @include view-at(sp) {
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 30px;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    margin-bottom: 20px;
                }
            }
            &-q {
                padding: 15px;
                font-size: 14px;
                position: relative;
                line-height: 1.5em;
                text-align: center;
                cursor: pointer;
                a {
                    text-decoration: none;
                }
                &::after {
                    content: '';
                    width: 20px;
                    height: 20px;
                    border: 0px;
                    border-bottom: solid 1px $color-gray;
                    border-right: solid 1px $color-gray;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 20%;
                    right: 15px;
                    transition: all 0.5s ease 0s;
                    @include view-at(sp) {
                        width: 15px;
                        height: 15px;
                        top: 30%;
                    }
                }

                &.on-click {
                    &::after {
                        top: 40%;
                        transform: rotate(225deg);
                    }
                }
            }
            &-a {
                padding: 15px;
                font-size: 14px;
                line-height: 1.8em;
                @include view-at(sp) {
                    padding: 15px 0;
                }

                h3 {
                 font-size: 18px;
                 letter-spacing: 0.1em;
                 margin-bottom: 5px;
                 line-height: 1.5em;
                }

                .closebtn {
                    border: 1px solid $color-gray;
                    text-align: center;
                    display: block;
                    width: 100%;
                    max-width: 350px;
                    text-decoration: none;
                    padding: 10px;
                    margin: 0 auto;
                    margin-bottom: 80px;
                    cursor: pointer;
                    @include view-at(sp) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.sitemap {
    &-wrap {
        margin-bottom: 40px;
        h3 {
            font-weight: bolder;
        }
    }
    &-list {
        margin-top: 15px;
        > li {
            position: relative;
            padding-left: 15px;
            margin-bottom: 30px;
            &::after {
                content: '';
                width: 8px;
                height: 8px;
                border: 0px;
                border-bottom: solid 1px $color-gray;
                border-right: solid 1px $color-gray;
                transform: rotate(-45deg);
                position: absolute;
                top: 4px;
                left: 0;
                transition: all 0.5s ease 0s;
                @include view-at(sp) {
                }
            }
        }

        &-in {
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            > li {
                position: relative;
                padding-left: 15px;
                margin-bottom: 15px;
                width: 25%;
                @include view-at(sp) {
                    width: 50%;
                }
                &::after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border: 0px;
                    border-bottom: solid 1px $color-gray;
                    border-right: solid 1px $color-gray;
                    transform: rotate(-45deg);
                    position: absolute;
                    top: 4px;
                    left: 0;
                    transition: all 0.5s ease 0s;
                    @include view-at(sp) {
                        
                    }
                }
            }
        }

        &-2 {
            display: flex;
            flex-wrap: wrap;
            margin-top:15px;
            > li {
                position: relative;
                padding-left: 15px;
                margin-bottom: 15px;
                width: 25%;
                @include view-at(sp) {
                    width: 50%;
                }
                &::after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border: 0px;
                    border-bottom: solid 1px $color-gray;
                    border-right: solid 1px $color-gray;
                    transform: rotate(-45deg);
                    position: absolute;
                    top: 4px;
                    left: 0;
                    transition: all 0.5s ease 0s;
                    @include view-at(sp) {
                    }
                }
            }

            &.sp100 {
                > li {
                    @include view-at(sp) {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-tel {
        justify-content: left;
        margin-bottom: 5px;
        margin-top: 20px;
    }
}

.company {
    &-list {
        li {
            margin-bottom: 40px;
            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            h3 {
                font-size: 20px;
                margin-bottom: 10px;
                letter-spacing: 0.1em;
            }

            iframe {
                height: 350px;
                width: 600px;
                @include view-at(sp) {
                    width: 100%;
                    height: 200px;
                }
            }
        }
    }
}

.error {
    h1 {
        font-size: 27px;
        letter-spacing: 0.2em;
        margin-bottom: 40px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        text-align: center;
        @include view-at(sp) {
            font-size: 20px;
            margin-bottom: 30px;
        }
    }
}

.brand {
    
    &-mv {
        margin-top: 20px;
        @include view-at(pc) {
            margin-top: 80px;
        } 
        &-img {
            margin-bottom: 80px;
            @include view-at(pc) {
                margin-left: 20%;
            }  
        }
        
        &-rel {
            position: relative;
        }

        h2 {
            font-size: 27px;
            letter-spacing: 0.4em;
            margin-bottom: 40px;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            color: $color-main;
            line-height: 2em;

            @include view-at(sp) {
                font-size: 20px;
                padding-left: 30px;
                padding-left: 30px;
            }  
        }
        &-text {
            text-align: right;
            padding-right: 20px;
            @include view-at(pc) {
                text-align: left;
                padding-right: 0;
            } 
            &-wrap {
                @include view-at(pc) {
                    margin-bottom: 80px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
            
            span {
                font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                color: $color-main;
                font-size: 15px;
                letter-spacing: 0.2em;
                line-height: 2.5em;
                display: inline-block;
                text-align: left;
                @include view-at(sp) {
                    font-size: 14px;
                }  
            }
        }
    }

    &-contents {
        position: relative;
        @include view-at(pc) {
            margin: 200px 0;
        }
        
        h2 {
            font-size: 18px;
            line-height: 1.5em;
            background: url(../../img/usr/others/brand/contents-bg.jpg) no-repeat;
            background-size: 100%;
            background-position: top center;
            padding: 20% 20px 80%;
            color: white;
            margin: 0 -20px;
            margin-right: 0;
            @include view-at(pc) {
                background: none;
                font-size: 27px;
                line-height: 1.5em;
                padding: 0;
                color: white;
                margin: 0 0 40px;
                margin-right: 0;
            } 
            
            span {
                background: white;
                padding: 5px 8px;
                font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                color: $color-main;
                letter-spacing: 0.2em;
            }

            &.second {
                background: url(../../img/usr/others/brand/contents-bg2.jpg) no-repeat;
                background-size: 100%;
                background-position: top center;
                margin: 0 -20px;
                margin-left: 0;
                text-align: right;
                @include view-at(pc) {
                    background: none;
                    text-align: left;
                    margin: 0 0 40px;
                }
            }
            &.third {
                background: url(../../img/usr/others/brand/contents-bg3.jpg) no-repeat;
                background-size: 100%;
                background-position: top center;
                @include view-at(pc) {
                    background: none;
                }
            }
        }
        &-img {
            padding-left: 40px;
            text-align: right;
        }
        &-text {
            font-size: 12px;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            letter-spacing: 0.1em;
            line-height: 2.5em;
            @include view-at(pc) {
                font-size: 15px;
            } 

            &-wrap {
                padding-left: 20px;
                @include view-at(pc) {
                    padding-left: 0;
                } 
            }
        }
        &-wrap {
            @include view-at(pc) {
                position: absolute;
                top: 50%;
                left: 60%;
                transform: translateY(-50%);
            }
        }
        h3 {
            font-size: 16px;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            color: $color-main;
            letter-spacing: 0.1em;
            line-height: 2em;
            @include view-at(pc) {
                font-size: 20px;
            }
        }

        &.second {
            @include view-at(pc) {
                .brand-contents-img {
                    padding-right: 40px;
                    padding-left: 0;
                    text-align: left;
                }
                .brand-contents-wrap {
                    position: absolute;
                    top: 50%;
                    left: 20%;
                    transform: translateY(-50%);
                }
            }
            
        }
    }

    &-link {
        margin: 180px -20px 80px;
        @include view-at(sp) {
            margin-bottom: 40px;
        }

        h3 {
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            color: $color-main;
            font-size: 18px;
            letter-spacing: 0.2em;
            text-align: center;
            margin-bottom: 20px;
            @include view-at(pc) {
                font-size: 22px;
                margin-bottom: 30px;
            }
        }
        &-grid {
            display: grid;
            display: -ms-grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            -ms-grid-columns: 1fr 1fr;
            -ms-grid-rows: 1fr 1fr;
            height: 300px;

            @include view-at(pc) {
                height: 350px;
                grid-template-columns: 2.5fr 1fr;
                -ms-grid-columns: 2.5fr 1fr;
            }

            li {
                background: $color-gray-snow;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                line-height: 1.5em;
                font-family: 'Tenor Sans', 'Noto Serif JP', serif;

                a {
                    color: $color-main;
                    letter-spacing: 0.15em;
                    display: block;
                    font-size: 17px;
                    line-height: 1.5em;
                    @include view-at(pc) {
                        font-size: 18px;
                    }

                    strong {
                        font-size: 20px;
                        @include view-at(pc) {
                            font-size: 30px;
                            line-height: 1.3em;
                        }
                    }
                }

                &:nth-child(1) {
                    grid-column: 1 / 3;
                    grid-row: 1 / 2;
                    -ms-grid-column: 1 / 3;
                    -ms-grid-row: 1 / 2;
                    background: url(../../img/usr/others/brand/menu1.png) no-repeat;
                    background-size: cover;
                    background-position: center;
                    align-items: flex-end;
                    justify-content: flex-start;
                    padding: 20px;
                    text-align: left;
                    @include view-at(pc) {
                        grid-column: 1 / 2;
                        grid-row: 1 / 3;
                        -ms-grid-column: 1 / 2;
                        -ms-grid-row: 1 / 3;
                        padding: 40px;
                    }
                }
                &:nth-child(2) {
                    grid-column: 1;
                    grid-row: 2;
                    -ms-grid-column:1;
                    -ms-grid-row: 2;
                    background: url(../../img/usr/others/brand/menu2.png) no-repeat;
                    background-size: cover;
                    background-position: center;
                    @include view-at(pc) {
                        grid-column: 2/ 3;
                        grid-row: 1 / 2;
                        -ms-grid-column: 2/ 3;
                        -ms-grid-row: 1 / 2;
                    }
                }
                &:nth-child(3) {
                    background: url(../../img/usr/others/brand/menu3.png) no-repeat;
                    background-size: cover;
                    background-position: center;
                    @include view-at(pc) {
                        grid-column: 2/ 3;
                        grid-row: 2/ 3;
                        -ms-grid-column: 2/ 3;
                        -ms-grid-row: 2/ 3;
                    }
                }
            }
        }
    }
    &-bnr {
        @include view-at(sp) {
            margin-top: 40px;
        }
        a {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid $color-gray;
            justify-content: center;
            align-items: center;
            max-width: 400px;
            margin: 0 auto;
        }
        &-img {
            width: 45%;
        }
        &-text {
            width: 55%;
            line-height: 1.8em;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            span {
                letter-spacing: 0.3em;
                position: relative;
                &::after {
                    content: "▶";
                    position: absolute;
                    bottom: 0;
                    right: 15px;
                    font-size: 6px;
                }
            }
        }
    }
}

.regular {
    &-copy {
        margin: 40px 0;
        @include view-at(pc) {
            margin: 80px 0;
        }
        &-text {
            font-size: 14px;
            line-height: 2em;
            letter-spacing: 0.1em;
            text-align: center;
        }
    }
    &-special {
        background: #3081be;
        padding: 40px 20px;
        margin: 40px -20px;
        @include view-at(pc) {
            padding: 80px;
            margin: 80px 0;
        } 

        h3 {
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            color: white;
            font-size: 18px;
            letter-spacing: 0.2em;
            text-align: center;
            margin-bottom: 15px;
            line-height: 1.5em;
            margin-bottom: 10px;
            @include view-at(pc) {
                font-size: 23px;
                margin-bottom: 30px;
            } 
        }
        &-card {
            padding: 20px;
            background: white;
            margin-bottom: 10px;

            @include view-at(pc) {
                padding: 30px;
                margin-bottom: 20px;
                &-wrap {
                    display: flex;
                    flex-wrap: wrap;
                    align-items:flex-start;
                    > div {
                        width: 48%;
                        margin: 1%;
                    }
                }
            } 
            
            &-img {
                margin: 0 -20px 20px;
                @include view-at(pc) {
                    margin: 0 -30px 20px;
                } 
                &-ttl {
                    font-size: 12px;
                    text-align: center;
                    color: $color-main;
                    @include view-at(pc) {
                        font-size: 14px;
                    }
                }
            }
            &-ttl {
                display: flex;
                flex-wrap: wrap;
                padding: 0 10px;
                margin-bottom: 10px;

                &.second {
                    align-items: center;
                }
                &-num {
                    font-size: 50px;
                    font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                    color: $color-gray-light;
                    width: 15%;
                }
                &-text {
                    font-size: 16px;
                    color: $color-main;
                    margin-bottom: 10px;
                    line-height: 1.5em;
                    width: 85%;
                    @include view-at(pc) {
                        font-size: 19px;
                    } 
                    small {
                        color: $color-text;
                        display: block;
                        line-height: 1.3em;
                        font-size: 12px;
                        margin-top: 5px;
                        @include view-at(pc) {
                            font-size: 14px;
                        } 
                    }
                    strong {
                        @include view-at(pc) {
                            font-size: 23px;
                        } 
                    }
                }
            }
            &-price {
                display: flex;
                justify-content: center;
                align-items: baseline;
                font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                margin-bottom: 10px;

                &-wrap {
                    padding: 0 10px;
                }

                &-ttl {
                    font-size: 14px;
                    margin-bottom: 10px;
                    color: $color-main;
                    text-align: center;
                    @include view-at(pc) {
                        font-size: 16px;
                    }
                    
                    span {
                        font-size: 10px;
                        padding: 2px 5px;
                        border: 1px solid #3081be;
                        border-radius: 5px;
                        display: inline-block;
                        margin-right: 10px;
                        color: #3081be;
                        @include view-at(pc) {
                            font-size: 12px;
                        }
                    }
                }

                &-before {
                    font-size: 12px;
                    text-decoration: line-through;
                    margin-right: 30px;
                    position: relative;
                    @include view-at(pc) {
                        font-size: 14px;
                    }
                    strong {
                        font-size: 17px;
                        @include view-at(pc) {
                            font-size: 20px;
                        }
                    }
                    small {
                        font-size: 9px;
                        @include view-at(pc) {
                            font-size: 10px;
                        }
                    }

                    &::after {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -20px;
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 5px solid $color-main;
                        border-top: 5px solid transparent;
                        border-bottom: 5px solid transparent;
                    }
                }
                &-after {
                    font-size: 14px;
                    color: $color-main;
                    @include view-at(pc) {
                        font-size: 17px;
                    }
                    strong {
                        font-size: 27px;
                        @include view-at(pc) {
                            font-size: 32px;
                        }
                    }
                    small {
                        font-size: 11px;
                        @include view-at(pc) {
                            font-size: 14px;
                        }
                    }
                }

                &-otoku {
                    display: inline-block;
                    background:#3081be;
                    color: white;
                    padding: 8px 40px;
                    font-size: 12px;
                    letter-spacing: 0.1em;
                    @include view-at(pc) {
                        font-size: 14px;
                    }
                }
            }
            h3 {
                color: $color-main;
                font-size: 16px;
                text-align: center;
                font-family: 'Noto Sans JP';
                font-weight: 500;
                letter-spacing: 0.05em;
                margin-bottom: 10px;
                span {
                    font-size: 27px;
                    font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                }
                sup {
                    font-size: 10px;
                }
            }
        }

        &.other {
            background: #e0ecf5;
        }
    }
    .other {
        background: #e0ecf5;

        h3 {
            color: $color-main;
        }

        &-top {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &-img {
                width: 50%;
                padding-right: 20px;
                img {
                    border: 1px solid $color-gray-snow;
                }
            }
            &-text {
                width: 50%;
                &-word {
                    font-size: 10px;
                    margin-bottom: 5px;
                    @include view-at(pc) {
                        font-size: 12px;
                    } 
                }
                &-name {
                    font-size: 14px;
                    margin-bottom: 10px;
                    @include view-at(pc) {
                        font-size: 16px;
                        line-height: 1.5em;
                    } 
                }
                &-tag {
                    margin-bottom: 20px;
                    span {
                        font-size: 10px;
                        padding: 5px 10px;
                        border: 1px solid #3081be;
                        border-radius: 5px;
                        display: inline-block;
                        margin-right: 10px;
                        color: #3081be;
                        @include view-at(pc) {
                            font-size: 12px;
                        } 
                    }
                }
            }
        }
        &-cp {
            background: #e0ecf5;
            padding: 15px;
            text-align: center;
            position: relative;
            margin-top: 30px;
            line-height: 1.5em;
            font-size: 14px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            span {
                color: #3081be;
                @include view-at(pc) {
                    font-size: 16px;
                } 
            }

            &::before {
                content: "";
                position: absolute;
                top: -30px;
                left: 50%;
                margin-left: -15px;
                border: 15px solid transparent;
                border-bottom: 15px solid #e0ecf5;
            }
            &-text {
                flex: 1;
                small {
                    font-size: 12px;
                }
            }
            &-img {
                flex: 0.3;
            }
        }
        &-btn {
            text-align: center;
            a {
                background: $color-main;
                color: white;
                border-color: $color-main;
                border-radius: 30px;
                width: auto;
                padding: 15px 50px;
                &::after {
                    color: white;
                }
            }
        }
    }
    &-item {
        margin: 40px 0;
        @include view-at(pc) {
            margin: 80px 0;
        } 
        &-nav {
            margin-bottom: 40px;
            li {
                width: calc(100% /3);
                a {
                    font-size: 13px;
                    letter-spacing: 0;
                    font-family: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
                    padding: 10px 5px;
                }
            }
        }
        &-list {
            padding-bottom: 20px;
            border-bottom: 1px solid $color-gray-light;
            margin-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            @include view-at(pc) {
                width: 48%;
                margin: 1%;
                border: 1px solid $color-gray-light;
                padding: 20px;
            } 
            &-img {
                width: 50%;
                &-text {
                    color: $color-main;
                    line-height: 1.5em;
                    margin-bottom: 10px;
                    font-size: 14px;
                }
            }
            &-text {
                width: 50%;
                padding-left: 20px;

                &-word {
                    line-height: 1.3em;
                    margin-bottom: 10px;
                    font-size: 12px;
                }
                &-tag {
                    font-size: 10px;
                    padding: 2px 5px;
                    border: 1px solid #3081be;
                    border-radius: 5px;
                    display: inline-block;
                    margin-right: 10px;
                    color: #3081be;
                    margin-bottom: 10px;
                    letter-spacing: 0.1em;
                    @include view-at(pc) {
                        font-size: 12px;
                    }
                }
                &-price {
                    margin-bottom: 10px;
                    &-before {
                        font-size: 12px;
                        text-decoration: line-through;
                        margin-bottom: 5px;
                    }
                    &-after {
                        font-size: 12px;
                        color: $color-main;
                        padding-left: 10px;
                        position: relative;
                        
                        strong {
                            font-size: 16px;
                            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                            @include view-at(pc) {
                                font-size: 19px;
                                line-height: 1.3;
                            }
                        }
                        small {
                            font-size: 8px;
                            @include view-at(pc) {
                                font-size: 10px;
                            }
                        }
                        &::after {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            content: '';
                            width: 0;
                            height: 0;
                            border-left: 3px solid $color-main;
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                        }
                    }
                }
                &-btn {
                    a {
                        display: block;
                        background: $color-main;
                        text-align: center;
                        color: white;
                        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
                        font-size: 12px;
                        padding: 10px;
                        position: relative;
                        @include view-at(pc) {
                            font-size: 14px;
                        }
                        &::after {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 10px;
                            content: '';
                            width: 0;
                            height: 0;
                            border-left: 5px solid $color-white;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                        }
                    }
                }
            }
        }
        &-text {
            font-size: 16px;
            text-align: center;
            line-height: 1.8em;
            color: $color-main;
        }
    }
    &-voice {
        margin: 40px 0 120px;
        @include view-at(pc) {
            margin: 80px 0;
        }
        h3 {
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            color: $color-main;
            font-size: 18px;
            letter-spacing: 0.2em;
            text-align: center;
            margin-bottom: 40px;
            line-height: 1.8em;
            @include view-at(pc) {
                font-size: 20px;
            } 
        }

        &-wrap {
            padding-bottom: 20px;
            border-bottom: 1px solid $color-gray-light;
            margin-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            @include view-at(pc) {
                width: 43%;
                margin: 3.5%;
                border-top: 1px solid $color-gray-light;
                border-bottom: none;
                padding-top: 40px;
                padding-bottom: 0;
            }
            @include view-at(sp) {
                &:nth-last-child(1) {
                    border: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
            
        }
        

        &-img {
            width: 30%;
            padding-right: 20px;
            @include view-at(pc) {
                padding-right: 30px;
            }
        }
        &-text {
            width: 70%;
            &-ttl {
                color: $color-main;
                margin-bottom: 10px;
                font-size: 12px;
                line-height: 1.5em;
                @include view-at(pc) {
                    font-size: 16px;
                }
            }
            &-in {
                font-size: 10px;
                line-height: 1.8em;
                @include view-at(pc) {
                    font-size: 12px;
                }
            }
        }
    }
    &-color {
        &-ttl {
            border-bottom: 1px solid $color-gray-snow;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            padding: 0 20px;
            margin-bottom: 10px;

            li {
                padding: 5px;
                width: 25%;
                img {
                    padding: 5px;
                }
                &.active {
                    img {
                        border: 1px solid red;
                        border-radius: 5px;
                    }
                }
                &:hover {
                    img {
                        border: 1px solid red;
                    border-radius: 5px;
                    }
                }
            }

            &-text {
                font-size: 10px;
                text-align: center;
                line-height: 1;
                @include view-at(pc) {
                    font-size: 12px;
                } 
            }
        }
    }
}

.members {
    &-copy {
        margin: 40px 0;
        @include view-at(pc) {
            margin: 80px 0;
         }
        &-text {
            font-size: 14px;
            line-height: 2em;
            letter-spacing: 0.1em;
            text-align: center;
        }
    }
    &-list {
        margin: 80px 0;
        ul {
            @include view-at(pc) {
                display: flex;
                flex-wrap: wrap;
             }
        }
        &-item {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid $color-gray-snow;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @include view-at(pc) {
                width: 45%;
                margin: 2.5%;
             }
            &:nth-last-child(1) {
                
                @include view-at(sp) {
                    border: none;
                    margin: 0;
                    padding: 0;
                 }
            }
            &-img {
                width: 30%;
            }
            &-text {
                width: 70%;
                padding-left: 20px;
                font-size: 12px;
                line-height: 1.5em;

                &-ttl {
                    font-size: 16px;
                    color: $color-main;
                    line-height: 1.5em;
                    margin-bottom: 10px;
                }
            }
        }
    }
    &-point {
        margin: 80px 0;
        &-wrap {
            background: #3081be;
            padding: 30px;
            color: white;
            max-width: 600px;
            margin: 0 auto;
        }
        h3 {
            font-size: 20px;
            line-height: 1.5em;
            text-align: center;
            margin-bottom: 20px;
            @include view-at(pc) {
                font-size: 26px;
             }
            small {
                font-size: 14px;
                @include view-at(pc) {
                    font-size: 16px;
                 }
            }
        }
        &-text {
            font-size: 13px;
            text-align: center;
            margin-bottom: 20px;
            @include view-at(pc) {
                font-size: 16px;
                line-height: 1.8em;
             }
        }
        &-btn {
            background: white;
            padding: 15px;
            width: 250px;
            margin: 0 auto;
            font-size: 16px;
            color: #3081be;
            border-radius: 30px;
            display: block;
            text-align: center;
            line-height: 1;
        }
        &-bnr {
            margin-bottom: 20px;
            @include view-at(pc) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                
                &-text {
                    width: 70%;
                    
                }
                &-img {
                   width: 30%;
                }
             }
        }
    }
}

.point {
    &-new {
        background: #3081be;
        padding: 20px 40px;
        display: flex;
        flex-wrap: wrap;
        color: white;
        align-items: center;
        justify-content: center;

        &-ttl {
            font-size: 22px;
            padding-right: 20px;
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            letter-spacing: 0.2em;
        }
        &-text {
            border-left: 1px solid;
            padding-left: 20px;
            line-height: 1.5em;
            letter-spacing: 0.1em;
        }
    }
    &-copy {
        margin: 40px 0;
        @include view-at(pc) {
            margin: 80px 0;
         }
        h3 {
            font-family: 'Tenor Sans', 'Noto Serif JP', serif;
            color: $color-main;
            font-size: 18px;
            letter-spacing: 0.2em;
            text-align: center;
            line-height: 1.5em;
            margin-bottom: 20px;
            font-weight: 300;
            @include view-at(pc) {
                font-size: 24px;
             }
        }
        &-text {
            font-size: 14px;
            line-height: 2em;
            letter-spacing: 0.1em;
            text-align: center;
            @include view-at(pc) {
                font-size: 16px;
             }
        }
    }
    &-list {
        margin: 80px 20px;
        ul {
            padding: 0 20px;
            @include view-at(pc) {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
             }
        }
        &-item {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid $color-gray-snow;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @include view-at(pc) {
                width: calc(100%/3);
                border-right: 1px solid $color-gray-snow;
                border-bottom: none;
                padding: 0 20px;
                margin: 0;
                display: block;
             }
            &:nth-last-child(1) {
                border: none;
                margin: 0;
                padding: 0;
                @include view-at(pc) {
                    padding: 0 20px;
                    border-right: none;
                 }
            }
            &-img {
                width: 30%;

                @include view-at(pc) {
                    width: 100%;
                    margin-bottom: 20px;
                    > div {
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            height: 100%;
                            width: auto;
                        }
                    }
                 }
            }
            &-text {
                width: 70%;
                padding-left: 20px;
                font-size: 14px;
                color: $color-main;
                line-height: 1.8em;
                margin-bottom: 10px;
                @include view-at(pc) {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                 }
            }
        }
    }
    &-accumulate {
        margin: 80px 0;
        
        &-wrap {
            padding: 40px 30px;
            color: white;
            background: $color-main;
            @include view-at(pc) {
                padding: 80px;
             }
        }
        h3 {
            font-size: 20px;
            line-height: 1.5em;
            text-align: center;
            margin-bottom: 20px;
            font-weight: 400;
            @include view-at(pc) {
                font-size: 24px;
             }
            small {
                font-size: 14px;
            }
        }
        &-text {
            font-size: 14px;
            line-height: 1.5em;
            max-width: 600px;
            margin: 0 auto 20px;
            letter-spacing: 0.1em;
        }

        ul {
            padding: 0 20px;
            @include view-at(pc) {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
             }
        }
        &-list {
            margin: 80px 20px;
            &-item {
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid $color-gray-snow;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                @include view-at(pc) {
                    width: calc(100%/3);
                    border-right: 1px solid $color-gray-snow;
                    border-bottom: none;
                    padding: 0 20px;
                    margin: 0;
                    display: block;
                 }
                &:nth-last-child(1) {
                    border: none;
                    margin: 0;
                    padding: 0;
                    @include view-at(pc) {
                        padding: 0 20px;
                        border-right: none;
                     }
                }
                &-img {
                    width: 30%;
    
                    @include view-at(pc) {
                        width: 100%;
                        margin-bottom: 20px;
                        > div {
                            height: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                height: 100%;
                                width: auto;
                            }
                        }
                     }
                }
                &-text {
                    width: 70%;
                    padding-left: 20px;
                    font-size: 14px;
                    color: $color-white;
                    line-height: 1.8em;
                    margin-bottom: 10px;
                    @include view-at(pc) {
                        width: 100%;
                        text-align: center;
                        font-size: 18px;
                     }
                }
            }
        }
    }
    &-graph {
        margin: 80px 0;
        &-wrap {
            @include view-at(pc) {
               display: flex;
               flex-wrap: wrap;

               > div {
                   width: 45%;
                   margin: 2.5%;
               }
             }
        }
        h3 {
            font-size: 18px;
            line-height: 1.5em;
            text-align: center;
            margin-bottom: 20px;
            color: $color-main;
            font-weight: 400;
            @include view-at(pc) {
                font-size: 21px;
            }
            small {
                font-size: 14px;
            }
        }
        &-text {
            color: $color-main;
            font-size: 12px;
            text-align: center;
            line-height: 1.5em;
            @include view-at(pc) {
                font-size: 14px;
            }
        }
    }
    &-que {
        margin: 80px 0;
        &-list {
            margin: 0 20px;
            @include view-at(pc) {
                display: flex;
                flex-wrap: wrap;

                > div {
                    width: 50%;
                    padding: 20px;
                    &:nth-child(1) {
                        border-right: 1px solid $color-gray-snow;
                    }
                }
             }
            &-item {
                margin-bottom: 30px;
                &-ttl {
                    color: #1373bc;
                    letter-spacing: 0.1em;
                    font-size: 14px;
                    margin-bottom: 10px;
                }
                &-text {
                    font-size: 12px;
                    line-height: 1.5em;
                    margin-bottom: 10px;
                }
            }
        }

        &-btn {
            text-decoration:none;
            color:$color-main;
            display:inline-block;
            padding-right:50px;
            position:relative;
            font-size: 12px;
            margin-right: 20px;

            &::before, &::after{
                content:"";
                display:block;
                -webkit-transform:skewX(45deg);
                transform:skewX(45deg);
                position:absolute;
                right:0;
                bottom:2px;
            }
            &::before{
                width: 40px;
                height: 1px;
                background:$color-main;
            }
            &::after{
                width: 1px;
                height: 10px;
                background:$color-main;
                right: 3px;
            }
        }
    }
    &-caution {
        margin: 80px 0;
        background: $color-gray-snow;
        @include view-at(sp) {
            margin: 80px 20px;
         }
        &-wrap {
            
            padding: 30px 20px 40px;
        }
        h3 {
            font-size: 18px;
            line-height: 1.5em;
            text-align: center;
            margin-bottom: 30px;
            padding-bottom: 20px;
            color: $color-main;
            border-bottom: 1px solid $color-gray-light;
            @include view-at(pc) {
                font-size: 21px;
                padding-bottom: 10px;
                text-align: left;
             }
        }
        &-list {
            &-item {
                margin-bottom: 20px;
                &-ttl {
                    font-size: 13px;
                    line-height: 1.5em;
                    margin-bottom: 10px;
                    color: $color-main;
                    @include view-at(pc) {
                        font-size: 15px;
                     }
                }
                &-text {
                    font-size: 12px;
                    line-height: 1.8em;
                    @include view-at(pc) {
                        font-size: 14px;
                     }

                    li {
                        font-size: 12px;
                        line-height: 1.8em;
                        @include view-at(pc) {
                            font-size: 14px;
                         }
                    }
                }
            }
        }
    }
}

.mainte {
    h1 {
        font-size: 27px;
        letter-spacing: 0.2em;
        margin-bottom: 40px;
        font-family: 'Tenor Sans', 'Noto Serif JP', serif;
        text-align: center;
        color: $color-main;
        @include view-at(sp) {
            font-size: 20px;
            margin-bottom: 30px;
        }
    }
    p {
        letter-spacing: 0.1em;
        font-size: 16px;
        line-height: 2em;
        text-align: center;
        @include view-at(sp) {
            font-size: 14px;
        }
    }
}